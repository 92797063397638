const FailedIcon = () => `<svg
  xmlns="http://www.w3.org/2000/svg"
  width="100%"
  height="100%"
  viewBox="0 0 24 24"
  fill="none">
  <rect
    x="3"
    y="3"
    width="75%"
    height="75%"
    rx="1"
    stroke="red"
    stroke-width="2"
  />
  <path d="M21 3L3 21M3 3L21 21" stroke="red" stroke-width="2" />
</svg>`;
export default FailedIcon;
