import { css } from 'lit';
export const styles = css `
  /*
  @TODO:

  Move space & font sizes to Tokens
 */
  :host {
    --navbar-space-1: 8px;
    --navbar-space-5: 12px;
    --navbar-space-2: 16px;
    --navbar-space-3: 20px;
    --navbar-space-4: calc(var(--navbar-space-2) * 2);

    --navbar-padding-top: var(--navbar-space-5);
    --navbar-padding-bottom: var(--navbar-space-5);

    --section-space: 40px;
  }

  /*
    browser default reset
  */
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .navbar {
    background-color: var(--grain-color-background-1);
    padding-top: var(--navbar-padding-top);
    padding-right: var(--navbar-space-3);
    padding-bottom: var(--navbar-padding-bottom);
    padding-left: var(--navbar-space-3);
  }

  .search {
    margin-left: auto;
    margin-right: 16px;
  }

  .banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .menu {
    padding-top: 24px;
    padding-bottom: var(--section-space);
  }

  .primary-link-container {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    gap: 12px;
  }

  .icon-external {
    margin-left: 8px;
    margin-bottom: 3px;
  }

  .divider {
    display: block;
    height: 1px;
    background-color: var(--grain-global-color-gray-700);
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .secondary-link-container {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    column-gap: 20px;
    margin-top: 24px;
    margin-bottom: 0;
  }

  .nav-link--secondary {
    display: block;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 15px;
    line-height: 21px;
    color: var(--grain-color-typography-text-secondary);
    text-decoration: none;
  }

  .is-hidden {
    display: none;
  }

  .menu-button {
    display: flex;
    /*
      Reset default
      @TODO: Integrate Icon component
      into Button component
    */
    background-color: transparent;
    border: 0;
    padding: 0;
  }

  .menu-button {
    & :hover {
      cursor: pointer;
    }
  }

  .menu-content {
    margin-top: var(--section-space);
  }

  .menu-content-item {
    &:not(:last-child) {
      margin-bottom: var(--navbar-space-2);
    }
  }

  /* account variant */
  .menu-content-item--account {
    display: flex;

    & > * {
      flex: 1;
    }

    & :not(:last-child) {
      margin-right: 10px;
    }
  }
`;
