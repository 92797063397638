var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { html, LitElement } from 'lit';
import { registerCustomElement } from '../utils/registerCustomElement';
import { styles } from './styles';
import '../Icon';
import '../Button';
import { property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { Locales, setLocale, getLanguageCode, getLanguageName, getSelectedLanguageFromURL, reflectSelectedLanguageInURL } from '../locales/config';
import { msg, localized, str } from '@lit/localize';
import { populateOriginalSlotContent, updateSlots } from '../utils/slotUtils';
import { getCurrentYear } from '../utils/getCurrentYear';
import { isEmpty } from "lodash";
// prepare for OneTrust cookie management
let USResident = false;
let Footer = class Footer extends LitElement {
    constructor() {
        super(...arguments);
        this.isHub = false;
        this.languageSelectorEnabled = false;
        this.reflectLanguageInURL = false;
        this.initialLanguage = '';
        this.isDropdownOpen = false;
        this.languageSelected = Locales.EN;
        this.originalSlotContent = new Map();
        this.currentYear = getCurrentYear();
        /*
          OneTrust geolocation management
        */
        this._handleOneTrustGeo = () => {
            var _a, _b;
            const oneTrustAnchor = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.getElementById('ot-sdk-btn');
            // check window.OneTrust is available
            if (typeof window.OneTrust !== 'undefined' &&
                typeof window.OneTrust.getGeolocationData === 'function') {
                let geo = window.OneTrust.getGeolocationData();
                // united states-specific behavior
                if (((_b = geo.country) === null || _b === void 0 ? void 0 : _b.toUpperCase()) === 'US') {
                    USResident = true;
                    // reveal the link to avoid FOUC
                    oneTrustAnchor === null || oneTrustAnchor === void 0 ? void 0 : oneTrustAnchor.classList.remove('is-hidden');
                    // update component
                    this.requestUpdate();
                }
                else {
                    // reveal the link to avoid FOUC
                    oneTrustAnchor === null || oneTrustAnchor === void 0 ? void 0 : oneTrustAnchor.classList.remove('is-hidden');
                }
            }
            else {
                // OneTrust not available, set a timer to re-check
                setTimeout(this._handleOneTrustGeo, 100);
            }
        };
        this.handleOutsideClick = (event) => {
            var _a;
            const dropdownElement = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.footer-language-selector');
            if (dropdownElement &&
                !event.composedPath().includes(dropdownElement)) {
                this.isDropdownOpen = false;
                window.removeEventListener('click', this.handleOutsideClick); // Clean up listener
            }
            this.requestUpdate();
        };
    }
    /*
      OneTrust is a third-party cookie management
      vendor script included in consuming apps
    */
    handleOneTrust(e) {
        /*
          The OneTrust script cannot find the ID it is looking for
          inside the shadow DOM, so we add an event handler
          to reference the same function manually
        */
        // check window.OneTrust is available
        if (typeof window.OneTrust !== 'undefined' &&
            typeof window.OneTrust.ToggleInfoDisplay === 'function') {
            window.OneTrust.ToggleInfoDisplay();
            e.preventDefault();
        }
    }
    /*
      This method is invoked when the element is first updated, it performs one time work on the element after update.
      We use it to set the language selected if the initialLanguage property is provided.
     */
    firstUpdated() {
        this.initLanguageSelector();
    }
    connectedCallback() {
        super.connectedCallback();
        // Subscribe to OneTrust when page loads
        window.addEventListener('load', this._handleOneTrustGeo);
        // injects a style block into the consuming HTML document.
        // this style block is used to style the links that will be slotted into
        // this component's template
        document.head.insertAdjacentHTML('beforeend', `<style>
        .link-wrapper:not(:last-child) {
          margin-block-end: 16px;
        }
        .link--primary {
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          color: var(--grain-global-color-white-100);
          text-decoration: none;
        }
        .link--primary:hover {
          text-decoration: underline;
        }
        .link--secondary {
          font-size: 13px;
          font-weight: 400;
          line-height: 16px;
          color: var(--grain-global-color-gray-300);
          text-decoration: none;
        }
        .link--secondary:hover {
          text-decoration: underline;
        }

        [slot="features-heading"] {
          color: white;
          text-decoration: none;
        }
        [slot="features-heading"]:hover {
          text-decoration: underline;
        }
      </style>`);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        // disconnect event listener
        window.removeEventListener('load', this._handleOneTrustGeo);
    }
    handleSlotchange(e) {
        const childElements = e.target.assignedElements();
        for (const el of childElements) {
            el.classList.remove('slot--hidden');
            if (el instanceof HTMLElement) {
                populateOriginalSlotContent(el, this.originalSlotContent);
            }
        }
        updateSlots(this.shadowRoot, this.originalSlotContent);
        this.requestUpdate();
    }
    initLanguageSelector() {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            if (!this.languageSelectorEnabled) {
                return;
            }
            const langInURL = isEmpty(this.initialLanguage) ? getSelectedLanguageFromURL() : this.initialLanguage;
            if (!langInURL || langInURL === this.languageSelected) {
                return;
            }
            yield this.setSelectedLanguage(langInURL);
            (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelectorAll('slot').forEach(slot => {
                slot.assignedNodes()
                    .filter((node) => node instanceof HTMLElement)
                    .forEach(node => populateOriginalSlotContent(node, this.originalSlotContent));
            });
        });
    }
    setSelectedLanguage(language) {
        return __awaiter(this, void 0, void 0, function* () {
            this.languageSelected = getLanguageCode(language);
            yield setLocale(this.languageSelected);
            updateSlots(this.shadowRoot, this.originalSlotContent);
            this.requestUpdate();
            if (this.reflectLanguageInURL) {
                reflectSelectedLanguageInURL(this.languageSelected);
            }
        });
    }
    handleLanguageSelect(e) {
        const target = e.target;
        const lang = target.getAttribute('data-value');
        this.isDropdownOpen = false;
        this.languageSelected = (lang || Locales.EN);
        this.setSelectedLanguage(lang || 'en');
    }
    toggleDropdown() {
        this.isDropdownOpen = !this.isDropdownOpen;
        if (this.isDropdownOpen) {
            window.addEventListener('click', this.handleOutsideClick);
        }
        else {
            window.removeEventListener('click', this.handleOutsideClick);
        }
        this.requestUpdate();
    }
    renderLanguageOption(lang) {
        if (this.languageSelected.includes(lang)) {
            return html `
        <span class="dropdown-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M1.66675 7.66671L6.33341 12.3334L14.3334 4.33337"
              stroke="white"
              stroke-width="2"
            />
          </svg>
        </span>
      `;
        }
        return null;
    }
    render() {
        const footerWrapperClasses = classMap({
            'footer-wrapper-condensed': this.isHub,
            'footer-wrapper': !this.isHub,
        });
        return html `
      <footer class="grain-footer">
        <div class=${footerWrapperClasses}>
          <div class="footer-content">
            <!-- Identity section -->
            ${!this.isHub
            ? html ` <div class="footer-identity">
                    <div class="footer-logo">
                      <a href="https://www.vsco.co" alt="">
                        <grain-icon
                          name="vscoWordmark"
                          fill="var(--footer-logo-fill)"
                          width="86px"
                          height="40px"
                        ></grain-icon>
                      </a>
                    </div>
                    <div class="footer-engagement">
                      <ul class="footer-engagement-list">
                        <li class="footer-engagement-item">
                          <grain-button
                            as="a"
                            href="${msg('https://vsco.co/subscribe/start')}"
                            variant="secondary"
                            id="footer-see-plans"
                            size="lg"
                            fullWidth
                          >
                            ${msg('Try for free')}
                          </grain-button>
                        </li>
                        <li class="footer-engagement-item">
                          <grain-button
                            as="a"
                            href="${msg('https://vs.co/download')}"
                            variant="secondary"
                            id="footer-download-app"
                            size="lg"
                            fullWidth
                          >
                            ${msg('Download now')}
                          </grain-button>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <nav class="footer-primary">
                    <div class="footer-primary-wrapper">
                      <div class="footer-masthead">
                        <div class="footer-headline">
                          <h4 class="footer-headline-text">
                            ${msg('So you can make it')}
                          </h4>
                        </div>
                      </div>

                      <div class="footer-index">
                        <section class="footer-index-section">
                          <h5 class="footer-index-headline">
                            ${msg('Company')}
                          </h5>
                          <!-- company links will be slotted below -->
                          <slot
                            name="company"
                            @slotchange=${this.handleSlotchange}
                          ></slot>
                        </section>

                        <section class="footer-index-section">
                          <!-- feature heading link will be slotted below -->
                          <h5 class="footer-index-headline">
                            <slot
                              name="features-heading"
                              @slotchange=${this.handleSlotchange}
                            ></slot>
                          </h5>
                          <!-- feature links will be slotted below -->
                          <slot
                            name="features"
                            @slotchange=${this.handleSlotchange}
                          ></slot>
                        </section>

                        <section class="footer-index-section">
                          <h5 class="footer-index-headline">
                            ${msg('Community')}
                          </h5>
                          <!-- community links will be slotted below -->
                          <slot
                            name="community"
                            @slotchange=${this.handleSlotchange}
                          ></slot>
                        </section>

                        <section class="footer-index-section">
                          <h5 class="footer-index-headline">
                            ${msg('Guides')}
                          </h5>
                          <!-- guides links will be slotted below -->
                          <slot
                            name="guides"
                            @slotchange=${this.handleSlotchange}
                          ></slot>
                        </section>
                      </div>
                    </div>
                  </nav>

                  <span class="footer-divider"></span>`
            : null}

            <!-- Legal section -->
            <nav class="footer-secondary">
              <div class="footer-legal">
                <div class="footer-terms">
                  <ul class="footer-terms-list">
                    <li class="footer-terms-list-item">
                      <!-- Language selector -->
                      ${this.languageSelectorEnabled
            ? html `
                            <div class="footer-language-selector">
                              <button
                                class="dropdown-button"
                                aria-haspopup="true"
                                aria-expanded="${this.isDropdownOpen}"
                                @click="${this.toggleDropdown}"
                              >
                                ${getLanguageName(this.languageSelected)}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path d="M3 6L8 11L13 6" stroke="white" />
                                </svg>
                              </button>
                              ${this.isDropdownOpen
                ? html `
                                    <ul class="dropdown-menu">
                                      <li
                                        class="dropdown-option"
                                        data-value="en"
                                        @click="${this.handleLanguageSelect}"
                                      >
                                        ${msg('English')}
                                        ${this.renderLanguageOption(Locales.EN)}
                                      </li>
                                      <li
                                        class="dropdown-option"
                                        data-value="es"
                                        @click="${this.handleLanguageSelect}"
                                      >
                                        ${msg('Español (México)')}
                                        ${this.renderLanguageOption(Locales.ES)}
                                      </li>
                                      <li
                                        class="dropdown-option"
                                        data-value="pt"
                                        @click="${this.handleLanguageSelect}"
                                      >
                                        ${msg('Português (Brasil)')}
                                        ${this.renderLanguageOption(Locales.PT)}
                                      </li>
                                    </ul>
                                  `
                : null}
                            </div>
                          `
            : null}
                    </li>
                    <li class="footer-terms-list-item">
                      <!-- TOS link will be slotted below -->
                      <slot
                        name="terms"
                        @slotchange=${this.handleSlotchange}
                      ></slot>
                    </li>
                    <li class="footer-terms-list-item">
                      <!-- Hub agreement link will be slotted below -->
                      <slot
                        name="hub-agreement"
                        @slotchange=${this.handleSlotchange}
                      ></slot>
                    </li>
                    <li class="footer-terms-list-item">
                      <!-- Privacy policy link will be slotted below -->
                      <slot
                        name="privacy-policy"
                        @slotchange=${this.handleSlotchange}
                      ></slot>
                    </li>
                    <li
                      class="footer-terms-list-item  footer-terms-list-item--cookies"
                    >
                      <a
                        href="#"
                        id="ot-sdk-btn"
                        class="one-trust-anchor  ot-sdk-show-settings  is-hidden"
                        @click="${this.handleOneTrust}"
                      >
                        ${USResident
            ? html `<grain-icon
                                name="oneTrust"
                                class="one-trust-icon  js-one-trust-icon"
                                width="30px"
                                height="16px"
                              ></grain-icon
                              >${msg('Your Privacy Choices')}`
            : html `${msg('Cookie Settings')}`}
                      </a>
                    </li>
                  </ul>
                </div>

                <div class="footer-copyright">
                  <p class="footer-copyright-text">
                    ${msg(str `Copyright ${this.currentYear} VSCO. All rights reserved.`)}
                  </p>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </footer>
    `;
    }
};
Footer.styles = styles;
__decorate([
    property({ type: Boolean })
], Footer.prototype, "isHub", void 0);
__decorate([
    property({ type: Boolean })
], Footer.prototype, "languageSelectorEnabled", void 0);
__decorate([
    property({ type: Boolean })
], Footer.prototype, "reflectLanguageInURL", void 0);
__decorate([
    property({ type: String })
], Footer.prototype, "initialLanguage", void 0);
__decorate([
    state()
], Footer.prototype, "isDropdownOpen", void 0);
__decorate([
    state()
], Footer.prototype, "languageSelected", void 0);
Footer = __decorate([
    localized(),
    registerCustomElement('grain-footer')
], Footer);
export { Footer };
