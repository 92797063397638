const DodgeIcon = () => `<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" fill="none">
  <g clip-path="url(#clip0_154_2527)">
    <circle cx="15" cy="9" r="9" fill="currentColor"/>
    <circle opacity="0.5" cx="12" cy="12" r="9" fill="currentColor"/>
    <circle opacity="0.2" cx="9" cy="15" r="9" fill="currentColor"/>
  </g>
  <defs>
    <clipPath id="clip0_154_2527">
      <rect width="100%" height="100%" fill="white"/>
    </clipPath>
  </defs>
</svg>`;
export default DodgeIcon;
