var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
const getDefaultHeaders = (authToken) => {
    return {
        Authorization: authToken ? `Bearer ${authToken}` : undefined,
        'Content-Type': 'application/json',
        'X-Client-Platform': 'web',
        'X-Client-Build': '1',
    };
};
function request(axiosCallback) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield axiosCallback();
            return response.data;
        }
        catch (error) {
            const e = error;
            const errorMessage = e.message || e.toString();
            throw new Error(errorMessage);
        }
    });
}
export function get(host_1, authToken_1, endpoint_1) {
    return __awaiter(this, arguments, void 0, function* (host, authToken, endpoint, params = {}, config) {
        const queryString = Object.keys(params).length > 0
            ? `?${new URLSearchParams(params).toString()}`
            : '';
        const url = `${host}/${endpoint}${queryString}`;
        const defaultHeaders = getDefaultHeaders(authToken);
        return request(() => axios.get(url, Object.assign(Object.assign({}, config), { headers: Object.assign(Object.assign({}, defaultHeaders), config === null || config === void 0 ? void 0 : config.headers) })));
    });
}
export function post(host_1, authToken_1, endpoint_1) {
    return __awaiter(this, arguments, void 0, function* (host, authToken, endpoint, data = {}, config) {
        const url = `${host}/${endpoint}`;
        const defaultHeaders = getDefaultHeaders(authToken);
        return request(() => axios.post(url, data, Object.assign(Object.assign({}, config), { headers: Object.assign(Object.assign({}, defaultHeaders), config === null || config === void 0 ? void 0 : config.headers) })));
    });
}
export function put(host_1, authToken_1, endpoint_1) {
    return __awaiter(this, arguments, void 0, function* (host, authToken, endpoint, data = {}, config) {
        const url = `${host}/${endpoint}`;
        const defaultHeaders = getDefaultHeaders(authToken);
        return request(() => axios.put(url, data, Object.assign(Object.assign({}, config), { headers: Object.assign(Object.assign({}, defaultHeaders), config === null || config === void 0 ? void 0 : config.headers) })));
    });
}
export function del(host, authToken, endpoint, config) {
    return __awaiter(this, void 0, void 0, function* () {
        const url = `${host}/${endpoint}`;
        const defaultHeaders = getDefaultHeaders(authToken);
        return request(() => axios.delete(url, Object.assign(Object.assign({}, config), { headers: Object.assign(Object.assign({}, defaultHeaders), config === null || config === void 0 ? void 0 : config.headers) })));
    });
}
