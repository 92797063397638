import { css } from 'lit';
export const styles = css `
  .title {
    margin: 0;
    font-size: 21px;
    font-weight: 500;
    line-height: 28px;
  }

  .split {
    margin-top: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 16px;
  }

  .qr {
    flex: 1;
    min-width: 0;

    img {
      width: 100%;
      height: auto;
    }
  }

  .text {
    flex: 1;
    min-width: 0;

    p {
      font-size: 13px;
      font-weight: 500;
      margin: 0;
    }

    p + p {
      margin-top: 12px;
    }
  }

  .buttons {
    margin-top: 48px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 16px;

    & > * {
      flex: 1;
      min-width: 0;
      max-width: 50%;
    }
  }
`;
