export var SubscriptionSource;
(function (SubscriptionSource) {
    SubscriptionSource[SubscriptionSource["UNKNOWN"] = 0] = "UNKNOWN";
    SubscriptionSource[SubscriptionSource["IOS"] = 1] = "IOS";
    // A "comped" subscription which may be given by an admin
    // or as part of a promotional campaign. These comps are
    // managed through `subscription-api`
    SubscriptionSource[SubscriptionSource["COMP"] = 2] = "COMP";
    // The Google Play store.
    SubscriptionSource[SubscriptionSource["ANDROID"] = 3] = "ANDROID";
    // Stripe payment processor
    SubscriptionSource[SubscriptionSource["STRIPE"] = 6] = "STRIPE";
})(SubscriptionSource || (SubscriptionSource = {}));
