import { default as AddPlusCircleIcon } from './icon-items/AddPlusCircleIcon';
import { default as AddPlusCircleInvertIcon } from './icon-items/AddPlusCircleInvertIcon';
import { default as AdjustIcon } from './icon-items/AdjustIcon';
import { default as AdobeLightroomClassicLogoIcon } from './icon-items/AdobeLightroomClassicLogoIcon';
import { default as AdobeLightroomLogoIcon } from './icon-items/AdobeLightroomLogoIcon';
import { default as AllPhotosIcon } from './icon-items/AllPhotosIcon';
import { default as AppleUpgradeQRIcon } from './icon-items/AppleUpgradeQR';
import { default as ArrowBackIcon } from './icon-items/ArrowBackIcon';
import { default as ArrowExternalIcon } from './icon-items/ArrowExternalIcon';
import { default as ArrowForwardIcon } from './icon-items/ArrowForwardIcon';
import { default as AspectRatioAllIcon } from './icon-items/AspectRatioAllIcon';
import { default as AspectRatio_16_9_Icon } from './icon-items/AspectRatio_16_9_Icon';
import { default as AspectRatio_1_1_Icon } from './icon-items/AspectRatio_1_1_Icon';
import { default as AspectRatio_2_3_Icon } from './icon-items/AspectRatio_2_3_Icon';
import { default as AspectRatio_3_2_Icon } from './icon-items/AspectRatio_3_2_Icon';
import { default as AspectRatio_3_4_Icon } from './icon-items/AspectRatio_3_4_Icon';
import { default as AspectRatio_4_3_Icon } from './icon-items/AspectRatio_4_3_Icon';
import { default as AspectRatio_4_5_Icon } from './icon-items/AspectRatio_4_5_Icon';
import { default as AspectRatio_5_4_Icon } from './icon-items/AspectRatio_5_4_Icon';
import { default as AspectRatio_9_16_Icon } from './icon-items/AspectRatio_9_16_Icon';
import { default as BrushIcon } from './icon-items/BrushIcon';
import { default as BurnIcon } from './icon-items/BurnIcon';
import { default as CanvasIcon } from './icon-items/CanvasIcon';
import { default as CheckboxSelectedIcon } from './icon-items/CheckboxSelectedIcon';
import { default as CheckboxUnselectedIcon } from './icon-items/CheckboxUnselectedIcon';
import { default as CheckmarkIcon } from './icon-items/CheckmarkIcon';
import { default as ChevronIcon } from './icon-items/ChevronIcon';
import { default as CloseIcon } from './icon-items/CloseIcon';
import { default as CommentsIcon } from './icon-items/CommentsIcon';
import { default as CompareIcon } from './icon-items/CompareIcon';
import { default as CopyIcon } from './icon-items/CopyIcon';
import { default as DeleteIcon } from './icon-items/DeleteIcon';
import { default as DodgeIcon } from './icon-items/DodgeIcon';
import { default as EaselIcon } from './icon-items/EaselIcon';
import { default as EllipsesIcon } from './icon-items/EllipsesIcon';
import { default as EmailIcon } from './icon-items/EmailIcon';
import { default as FailedIcon } from './icon-items/FailedIcon';
import { default as FeedbackIcon } from './icon-items/FeedbackIcon';
import { default as FilterIcon } from './icon-items/FilterIcon';
import { default as FlipHorizontalIcon } from './icon-items/FlipHorizontalIcon';
import { default as FlipVerticalIcon } from './icon-items/FlipVerticalIcon';
import { default as FriendsIcon } from './icon-items/FriendsIcon';
import { default as FullGridIcon } from './icon-items/FullGridIcon';
import { default as GearIcon } from './icon-items/GearIcon';
import { default as GlobeIcon } from './icon-items/GlobeIcon';
import { default as GoogleUpgradeQRIcon } from './icon-items/GoogleUpgradeQR';
import { default as GridIcon } from './icon-items/GridIcon';
import { default as HamburgerIcon } from './icon-items/HamburgerIcon';
import { default as HappyFaceIcon } from './icon-items/HappyFaceIcon';
import { default as HubIcon } from './icon-items/HubIcon';
import { default as ImageUploadIcon } from './icon-items/ImageUploadIcon';
import { default as InformationIcon } from './icon-items/InformationIcon';
import { default as InstagramIcon } from './icon-items/InstagramIcon';
import { default as LearnIcon } from './icon-items/LearnIcon';
import { default as LockIcon } from './icon-items/LockIcon';
import { default as LogoutIcon } from './icon-items/LogoutIcon';
import { default as MagnifyingGlassIcon } from './icon-items/MagnifyingGlassIcon';
import { default as MinusIcon } from './icon-items/MinusIcon';
import { default as NavigationArrowRight } from './icon-items/NavigationArrowRight';
import { default as NewPostIcon } from './icon-items/NewPostIcon';
import { default as OneTrust } from './icon-items/OneTrust';
import { default as PasteIcon } from './icon-items/PasteIcon';
import { default as PencilIcon } from './icon-items/PencilIcon';
import { default as PhoneIcon } from './icon-items/PhoneIcon';
import { default as PlusIcon } from './icon-items/PlusIcon';
import { default as PresetIcon } from './icon-items/PresetIcon';
import { default as Profile } from './icon-items/Profile';
import { default as ProfileFaceIcon } from './icon-items/ProfileFaceIcon';
import { default as RedoIcon } from './icon-items/RedoIcon';
import { default as RemoveIcon } from './icon-items/RemoveIcon';
import { default as ReportIcon } from './icon-items/ReportIcon';
import { default as RotateLeftIcon } from './icon-items/RotateLeftIcon';
import { default as RotateRightIcon } from './icon-items/RotateRightIcon';
import { default as ShareIcon } from './icon-items/ShareIcon';
import { default as SocialLinkIcon } from './icon-items/SocialLinkIcon';
import { default as SpacesIcon } from './icon-items/SpacesIcon';
import { default as TikTokIcon } from './icon-items/TikTokIcon';
import { default as ToolsIcon } from './icon-items/ToolsIcon';
import { default as TwitterIcon } from './icon-items/TwitterIcon';
import { default as UndoIcon } from './icon-items/UndoIcon';
import { default as VisibilityIcon } from './icon-items/VisibilityIcon';
import { default as VisibilityOnIcon } from './icon-items/VisibilityOnIcon';
import { default as VscoLogoIcon } from './icon-items/VscoLogoIcon';
import { default as VscoLogoOutlineIcon } from './icon-items/VscoLogoOutlineIcon';
import { default as VscoWordmarkIcon } from './icon-items/VscoWordmarkIcon';
import { default as YouTubeIcon } from './icon-items/YoutubeIcon';
export const iconItems = {
    addPlusCircle: AddPlusCircleIcon,
    addPlusCircleInvert: AddPlusCircleInvertIcon,
    adjust: AdjustIcon,
    adobeLightroomLogo: AdobeLightroomLogoIcon,
    adobeLightroomClassicLogo: AdobeLightroomClassicLogoIcon,
    allPhotos: AllPhotosIcon,
    appleUpgradeQR: AppleUpgradeQRIcon,
    aspectRatio1_1: AspectRatio_1_1_Icon,
    aspectRatio16_9: AspectRatio_16_9_Icon,
    aspectRatio2_3: AspectRatio_2_3_Icon,
    aspectRatio3_2: AspectRatio_3_2_Icon,
    aspectRatio3_4: AspectRatio_3_4_Icon,
    aspectRatio4_3: AspectRatio_4_3_Icon,
    aspectRatio4_5: AspectRatio_4_5_Icon,
    aspectRatio5_4: AspectRatio_5_4_Icon,
    aspectRatio9_16: AspectRatio_9_16_Icon,
    aspectRatioAll: AspectRatioAllIcon,
    arrowBack: ArrowBackIcon,
    arrowForward: ArrowForwardIcon,
    brush: BrushIcon,
    burn: BurnIcon,
    canvas: CanvasIcon,
    checkmark: CheckmarkIcon,
    close: CloseIcon,
    comments: CommentsIcon,
    compare: CompareIcon,
    copy: CopyIcon,
    delete: DeleteIcon,
    dodge: DodgeIcon,
    ellipses: EllipsesIcon,
    explore: GlobeIcon,
    external: ArrowExternalIcon,
    failed: FailedIcon,
    feedback: FeedbackIcon,
    filter: FilterIcon,
    flipHorizontal: FlipHorizontalIcon,
    flipVertical: FlipVerticalIcon,
    friends: FriendsIcon,
    fullGrid: FullGridIcon,
    gear: GearIcon,
    googleUpgradeQR: GoogleUpgradeQRIcon,
    grid: GridIcon,
    hamburger: HamburgerIcon,
    happyFace: HappyFaceIcon,
    hub: HubIcon,
    imageUploadIcon: ImageUploadIcon,
    informationIcon: InformationIcon,
    learn: LearnIcon,
    lock: LockIcon,
    logout: LogoutIcon,
    minus: MinusIcon,
    navigationArrowRight: NavigationArrowRight,
    newPost: NewPostIcon,
    oneTrust: OneTrust,
    paste: PasteIcon,
    plus: PlusIcon,
    preset: PresetIcon,
    profile: Profile,
    profileFace: ProfileFaceIcon,
    redo: RedoIcon,
    remove: RemoveIcon,
    report: ReportIcon,
    rotateLeft: RotateLeftIcon,
    rotateRight: RotateRightIcon,
    search: MagnifyingGlassIcon,
    selectedCheckbox: CheckboxSelectedIcon,
    share: ShareIcon,
    spaces: SpacesIcon,
    studio: EaselIcon,
    tools: ToolsIcon,
    undo: UndoIcon,
    unselectedCheckbox: CheckboxUnselectedIcon,
    visibility: VisibilityIcon,
    visibilityOn: VisibilityOnIcon,
    vscoLogo: VscoLogoIcon,
    vscoLogoOutline: VscoLogoOutlineIcon,
    vscoWordmark: VscoWordmarkIcon,
    pencil: PencilIcon,
    chevron: ChevronIcon,
    twitter: TwitterIcon,
    email: EmailIcon,
    instagram: InstagramIcon,
    phone: PhoneIcon,
    socialLinks: SocialLinkIcon,
    tiktok: TikTokIcon,
    youtube: YouTubeIcon,
};
