import { css } from 'lit';
export const styles = css `
  .label {
    display: block;
    cursor: pointer;
    position: relative;
    padding-left: 4px;
    font-size: 13px;
  }

  .checkbox-container {
    color: var(--grain-global-color-typography-text-secondary);
    display: flex;
    flex-direction: row;
    position: relative;
    margin-top: 16px;

    svg {
      pointer-events: none;
    }
  }

  .checked {
    color: var(--grain-global-color-typography-text-primary);
  }
  .checkbox {
    opacity: 0;
    position: absolute;
    top: 1px;
    left: 0px;
    cursor: pointer;
    width: 16px;
    height: 18px;
  }
  .disabled {
    pointer-events: none;
  }
`;
