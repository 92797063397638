const BurnIcon = () => `<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 27 24" fill="none">
  <g clip-path="url(#clip0_154_2533)">
    <circle cx="9.22754" cy="11.9994" r="9" transform="rotate(45 9.22754 11.9994)" fill="#222222"/>
    <circle cx="13.4707" cy="11.9994" r="9" transform="rotate(45 13.4707 11.9994)" fill="#444444"/>
    <circle cx="17.7129" cy="11.9994" r="8" transform="rotate(45 17.7129 11.9994)" fill="black" stroke="currentColor" stroke-width="2"/>
  </g>
  <defs>
    <clipPath id="clip0_154_2533">
      <rect width="100%" height="100%" fill="white"/>
    </clipPath>
  </defs>
</svg>`;
export default BurnIcon;
