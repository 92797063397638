var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html } from 'lit';
import { styles } from './styles';
import { registerCustomElement } from '../utils/registerCustomElement';
import '../Icon';
import '../Button';
import { Entitlement, hydrateEntitlement } from '../Entitlement';
import './NavbarItem';
import { localized, msg } from '@lit/localize';
import { localizeLink } from '../locales/config';
import { property } from 'lit/decorators.js';
let Navbar = class Navbar extends Entitlement {
    constructor() {
        super(...arguments);
        this.canvasNavEnabled = false;
    }
    menuToggle() {
        var _a, _b;
        (_a = this.renderRoot.querySelector('.js-menu')) === null || _a === void 0 ? void 0 : _a.classList.toggle('is-hidden');
        (_b = this.renderRoot
            .querySelector('.js-menu-toggle')) === null || _b === void 0 ? void 0 : _b.classList.toggle('is-hidden');
    }
    closeToggle() {
        var _a;
        (_a = this.renderRoot.querySelector('.js-close')) === null || _a === void 0 ? void 0 : _a.classList.toggle('is-hidden');
    }
    handleMenuToggle() {
        this.menuToggle();
        this.closeToggle();
    }
    render() {
        const canvasNavItem = this.canvasNavEnabled
            ? html ` <grain-navbar-item
          href=${hydrateEntitlement(localizeLink('//canvas.{{host}}'), this)}
          icon="canvas"
          id="navbar-canvas"
          isExternal="true"
        >
          <span>${msg('CANVAS')}</span>
          <grain-icon
            fill="white"
            width="16px"
            height="16px"
            name="external"
            class="icon-external"
          ></grain-icon>
        </grain-navbar-item>`
            : null;
        const accountNavItem = this.isLoggedIn
            ? html ` <span class="divider"></span>
          <grain-navbar-item
            href=${hydrateEntitlement(localizeLink('//{{host}}/user/account'), this)}
            icon="gear"
            id="navbar-account"
          >
            <span>${msg('ACCOUNT')}</span>
          </grain-navbar-item>`
            : null;
        return html `
      <div class="navbar">
        <section class="banner">
          <div class="logo">
            <a href="${localizeLink('https://www.vsco.co')}" alt="VSCO Home">
              <grain-icon
                name="vscoWordmark"
                fill="white"
                width="60px"
                height="28px"
              ></grain-icon>
            </a>
          </div>
          <div class="search">
            <a
              id="navbar-search"
              href=${hydrateEntitlement(localizeLink('//{{host}}/search'), this)}
              alt="Search icon"
            >
              <grain-icon name="search" fill="white"></grain-icon>
            </a>
          </div>
          <div class="menu-toggle  js-menu-toggle">
            <!-- 
              @TODO:
              Update Button to accept Icon component
            -->
            <button @click="${this.handleMenuToggle}" class="menu-button">
              <grain-icon name="hamburger" fill="white"></grain-icon>
            </button>
          </div>

          <div class="menu-close  js-close  is-hidden">
            <!-- 
              @TODO:
              Update Button to accept Icon component
            -->
            <button @click="${this.handleMenuToggle}" class="menu-button">
              <grain-icon name="close" fill="white"></grain-icon>
            </button>
          </div>
        </section>

        <section class="menu js-menu is-hidden">
          <nav>
            <!-- primary links -->
            <div class="primary-link-container">
              <!-- FEED -->
              <grain-navbar-item
                href=${hydrateEntitlement(localizeLink('//{{host}}/feed'), this)}
                icon="explore"
                id="navbar-feed"
              >
                <span>${msg('FEED')}</span>
              </grain-navbar-item>

              <!-- STUDIO -->
              <grain-navbar-item
                href="https://vs.co/Jg7o/gzzobx82"
                icon="studio"
                id="navbar-studio"
                isExternal="true"
              >
                <span>${msg('STUDIO')}</span>
                <grain-icon
                  fill="white"
                  width="16px"
                  height="16px"
                  name="external"
                  class="icon-external"
                ></grain-icon>
              </grain-navbar-item>

              <!-- CANVAS -->
              ${canvasNavItem}

              <!-- PROFILE -->
              <grain-navbar-item
                href=${!this.isLoggedIn
            ? hydrateEntitlement(localizeLink('//{{host}}/signup'), this)
            : hydrateEntitlement(localizeLink('//{{host}}/{{username}}'), this)}
                icon="profile"
                id="navbar-profile"
              >
                <span>${msg('PROFILE')}</span>
              </grain-navbar-item>

              <!-- SEARCH -->
              <grain-navbar-item
                href=${hydrateEntitlement(localizeLink('//{{host}}/search'), this)}
                icon="search"
                id="navbar-search"
              >
                <span>${msg('SEARCH')}</span>
              </grain-navbar-item>

              <!-- LEARN -->
              <grain-navbar-item
                href="${localizeLink('https://www.vsco.co/learn')}"
                icon="learn"
                id="navbar-learn"
              >
                <span>${msg('LEARN')}</span>
              </grain-navbar-item>

              <!-- ACCOUNT -->
              ${accountNavItem}

              <!-- HUB -->
              <span class="divider"></span>
              <grain-navbar-item
                href="${localizeLink('https://www.vsco.co/vsco-hub/hire')}"
                icon="hub"
                id="navbar-hub"
                isExternal="true"
              >
                <span>${msg('HUB')}</span>
                <grain-icon
                  fill="white"
                  width="16px"
                  height="16px"
                  name="external"
                  class="icon-external"
                ></grain-icon>
              </grain-navbar-item>
            </div>

            <div class="secondary-link-container">
              <a
                href="${localizeLink('https://www.vsco.co/features')}"
                id="navbar-index-features"
                class="nav-link--secondary"
              >
                ${msg('Features')}
              </a>

              <a
                href="${localizeLink('https://vsco.co/subscribe/start')}"
                id="navbar-index-plans"
                class="nav-link--secondary"
              >
                ${msg('Plans')}
              </a>

              <a
                href="${localizeLink('https://vsco.co/vsco/journal/p/1')}"
                id="navbar-index-whats-new"
                class="nav-link--secondary"
              >
                ${msg("What's New")}
              </a>

              <a
                href="${localizeLink('https://support.vsco.co/hc/en-us/community/topics')}"
                id="navbar-index-forum"
                class="nav-link--secondary"
              >
                ${msg('Forum')}
              </a>

              <a
                href="${localizeLink('https://support.vsco.co/hc/en-us')}"
                id="navbar-index-support"
                class="nav-link--secondary"
              >
                ${msg('Support')}
              </a>

              <a
                href="${localizeLink('https://www.vsco.co/safety')}"
                id="navbar-index-safety"
                class="nav-link--secondary"
              >
                ${msg('Safety')}
              </a>
            </div>
          </nav>

          ${!this.isLoggedIn
            ? html `
                <div class="menu-content">
                  <div class="menu-content-item">
                    <grain-button
                      as="a"
                      size="lg"
                      variant="primary"
                      href="${localizeLink('https://vs.co/download')}"
                      fullWidth
                      id="navbar-get-the-app"
                    >
                      ${msg('Get the app')}
                    </grain-button>
                  </div>

                  <div class="menu-content-item  menu-content-item--account">
                    <grain-button
                      as="a"
                      size="lg"
                      variant="secondary"
                      href=${hydrateEntitlement(localizeLink('//{{host}}/user/signup'), this)}
                      fullWidth
                      id="navbar-sign-up"
                    >
                      ${msg('Sign up')}
                    </grain-button>
                    <grain-button
                      as="a"
                      size="lg"
                      variant="secondary"
                      href=${hydrateEntitlement(localizeLink('//{{host}}/user/login'), this)}
                      fullWidth
                      id="navbar-log-in"
                    >
                      ${msg('Log in')}
                    </grain-button>
                  </div>
                </div>
              `
            : null}
        </section>
      </div>
    `;
    }
};
Navbar.styles = styles;
__decorate([
    property({ type: Boolean })
], Navbar.prototype, "canvasNavEnabled", void 0);
Navbar = __decorate([
    localized(),
    registerCustomElement('grain-navbar')
], Navbar);
export { Navbar };
