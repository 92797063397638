const AddPlusCircleIcon = () => `<svg
  viewBox="0 0 24 24"
  width="100%"
  height="100%"
  xmlns="http://www.w3.org/2000/svg"
  fill="currentColor">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="m13,6h-2v5h-5v2h5v5h2v-5h5v-2h-5v-5Z"
  />
  <path d="m12,24C5.38,24,0,18.62,0,12S5.38,0,12,0s12,5.38,12,12-5.38,12-12,12Zm0-22C6.49,2,2,6.49,2,12s4.49,10,10,10,10-4.49,10-10S17.51,2,12,2Z" />
</svg>`;
export default AddPlusCircleIcon;
