const ReportIcon = () => `<svg
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 16 16"
  width="100%"
  height="100%"
  fill="currentColor">
  <g>
    <path d="M16,15.3H0l8-14L16,15.3z M2.3,14h11.4L8,4L2.3,14z" />
  </g>
  <g>
    <rect x="7.3" y="6.7" width="1.3" height="4" />
  </g>
  <g>
    <circle cx="8" cy="12.3" r="1" />
  </g>
</svg>`;
export default ReportIcon;
