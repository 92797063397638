const CheckmarkIcon = () => `<svg
  xmlns="http://www.w3.org/2000/svg"
  width="100%"
  height="100%"
  viewBox="0 0 24 24"
  fill="none">
  <path
    d="M2.5 11.5L9.5 18.5L21.5 6.5"
    stroke="currentColor"
    stroke-width="2"
  />
</svg>`;
export default CheckmarkIcon;
