const AspectRatio_2_3_Icon = () => `<svg
  xmlns="http://www.w3.org/2000/svg"
  width="100%"
  height="100%"
  viewBox="0 0 24 24"
  fill="none">
  <path
    fill="currentColor"
    d="M20.3,24H4.8V0h15.6V24z M6.8,22h11.6V2H6.8V22z"
  />
</svg>`;
export default AspectRatio_2_3_Icon;
