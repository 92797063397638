const ProfileFaceIcon = () => `<svg
  width="100%"
  height="100%"
  viewBox="0 0 24 24"
  xmlns="http://www.w3.org/2000/svg"
  fill="none"
>
  <g>
    <circle id="Ellipse 71" cx="12" cy="12" r="11" stroke="currentColor" stroke-width="2" />
    <circle id="Ellipse 72" cx="8.5" cy="10.5" r="1.5" fill="currentColor" />
    <circle id="Ellipse 73" cx="15.5" cy="10.5" r="1.5" fill="currentColor" />
    <path
      d="M7.75781 16.2426C8.8436 17.3284 10.3436 18 12.0005 18C13.6574 18 15.1573 17.3285 16.2431 16.2427L14.8289 14.8285C14.1051 15.5523 13.1051 16 12.0005 16C10.8959 16 9.89589 15.5523 9.17203 14.8284L7.75781 16.2426Z"
      fill="currentColor"
    />
  </g>
</svg>  
  `;
export default ProfileFaceIcon;
