// Do not modify this file by hand!
// Re-generate this file by running lit-localize
import { str } from '@lit/localize';
/* eslint-disable no-irregular-whitespace */
/* eslint-disable @typescript-eslint/no-explicit-any */
export const templates = {
    's02a128deecd76592': `https://www.vsco.co/es-mx/vsco-hub`,
    's077cc274692ed6d7': `Conceptos básicos de fotografía`,
    's0e2e9cd0c7ba2c51': `REGISTRATE`,
    's13a2e3afa0779b53': `Planes`,
    's19b10d1a8593ca55': `Aprende`,
    's1e48e09152f88c54': `https://www.vsco.co/es-mx/features/community`,
    's212189a6300414ec': `Editor de fotos`,
    's21c4b4ad03e05269': `https://www.vsco.co/es-mx/features/photo-editor`,
    's239a3851e1d50b92': `Negocios de fotografía`,
    's2c362b854bbf7590': `Configuración de cookies`,
    's3273484a78b97574': `Colecciones de fotografías seleccionadas`,
    's3736464cf8d6fa08': `Servicio de atención`,
    's4653b68c582adb73': `Qué hay de nuevo`,
    's488d181316931103': `https://www.vsco.co/es-mx/features/photo-filters`,
    's494936b21828660d': `Comunidad creativa`,
    's498a0a19cdcb610c': `HUB`,
    's4caed5b7a7e5d89b': `English`,
    's5105e385c839fa25': `FEED`,
    's54b80acebd96daf2': `https://www.vsco.co/es-mx/about#press`,
    's56750e27217d1955': `APRENDE`,
    's592ec97ab73e8018': `Carreras`,
    's5e6b698a2ec87331': `Regístrate`,
    's6027cbc671d62950': `INICIA LA SESIÓN`,
    's65c3e033b5506816': `Tus opciones de privacidad`,
    's6a0bc0ce56a9ae4a': `Funciones`,
    's6b1ffa415c97de0b': `Historias de fotógrafos`,
    's71aaed0b53728ff5': `Así que puedes hacerlo`,
    's77aa17839291ed94': `Canvas VSCO`,
    's7cb4e55c59663b1c': `Compañía`,
    's7f724590eb8abf99': `https://vsco.co/subscribe/start`,
    's82a0d4177a41ba60': `https://vs.co/download`,
    's8642948ac334e994': `Prensa`,
    's8cdd9e134d0cc5a1': `Pruébalo gratis`,
    's91448dcac4812328': `Guías`,
    's977fac999ad9348a': `https://www.vsco.co/es-mx/about`,
    's98484c2fa289e12e': `PERFIL`,
    's9fc41f789c63b40d': `Productos`,
    'sa13e778658f3d8d1': `https://www.vsco.co/es-mx/learn`,
    'sa384dc1622f82116': `Foro`,
    'sa4858f3708bed642': `Português (Brasil)`,
    'sab07cf2bfae8483f': `Política de privacidad`,
    'sb02d8eb5c14ee92f': `https://www.vsco.co/es-mx/features/canvas`,
    'sb4de79af88ea14c6': `Condiciones de uso`,
    'sba1baaecc4139243': str `Copyright
                    ${0}
                    VSCO. Todos los derechos reservados.
                `,
    'sc2052449831b2c5c': `CUENTA`,
    'sc734b76bcb9a53e7': `Seguridad`,
    'sc8229c1b326021a9': `Guías de fotografía`,
    'scb04435672ac5410': `Inicia la sesión`,
    'scc26734c8f017a51': `CANVAS`,
    'sd0864ea6cb6ef22e': `Comunidad`,
    'sd4e5e0509cc3c976': `Directrices`,
    'sd596ce4ac14a69b7': `Obtiene la aplicación`,
    'sdb2e2df51e9c41d7': `Acerca de VSCO`,
    'sdba658848635c0f5': `Descargarla ahora`,
    'sddfcf9978e525329': `BUSCA`,
    'se0c38e02bbf8af7e': `https://www.vsco.co/es-mx/features`,
    'se4917d2c7e671e08': `Filtros de fotografía`,
    'se75befb41b923e54': `Español (México)`,
    'secdad4ae080e9927': `Hub VSCO`,
    'sf01935bab18089dc': `Aplicación móvil`,
    'sf3c2aace5830b503': `ESTUDIO`,
    'sf492a976b44e1fc7': `Acuerdo del Hub VSCO`,
};
