const CopyIcon = () => ` <svg
  xmlns="http://www.w3.org/2000/svg"
  width="100%"
  height="100%"
  viewBox="0 0 24 24"
  fill="currentColor">
  <path
    d="M21,7h-4V3c0-1.1-0.9-2-2-2H3C1.9,1,1,1.9,1,3v12c0,1.1,0.9,2,2,2h4v4c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V9
C23,7.9,22.1,7,21,7z M3,13.6l0-3.2L10.4,3h3.2L3,13.6z M3,3h4.6L3,7.6L3,3L3,3z M4.4,15L15,4.4v3.2L7.6,15H4.4z M15,10.4V15h-4.6
L15,10.4z M21,21H9v-4h6c1.1,0,2-0.9,2-2V9h4V21z"
  />
</svg>
    `;
export default CopyIcon;
