var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { consume } from '@lit/context';
import { LitElement, html } from 'lit';
import { property, state } from 'lit/decorators.js';
import { choose } from 'lit/directives/choose.js';
import { authenticationContext } from '../Authentication/context';
import { SubscriptionSource, } from '../Authentication/types';
import { get, post } from '../utils/apiService';
import { registerCustomElement } from '../utils/registerCustomElement';
import { styles } from './styles';
import '../Modal';
import './screens/Apple/Apple';
import './screens/Google/Google';
import './screens/Loading/Loading';
import './screens/Prompt/Prompt';
let ModalUpgrade = class ModalUpgrade extends LitElement {
    constructor() {
        super(...arguments);
        // Flow state - Used to show prompt, continue on ios, or continue on android
        this.flowState = 'loading';
        // Parameters for additional customization
        this.promptDescription = '';
        // Set flow state
        this._setFlowState = () => {
            // Determine current flow
            const getFlowState = () => {
                // Loading
                if (!this.authentication)
                    return 'loading';
                if (this.authentication.reqStatus === 'idle')
                    return 'loading';
                if (this.authentication.reqStatus === 'loading')
                    return 'loading';
                // Failed
                if (this.authentication.reqStatus === 'failed')
                    return 'failed';
                // Prompt
                return 'prompt';
            };
            // Use current view to update state
            const newFlowState = getFlowState();
            if (this.flowState !== newFlowState) {
                this.flowState = newFlowState;
                this.requestUpdate();
            }
        };
        // Choose product
        this._chooseProduct = (product_1, ...args_1) => __awaiter(this, [product_1, ...args_1], void 0, function* (product, forceStripe = false) {
            // Data checking
            if (!product)
                return;
            if (!this.authentication)
                return;
            if (!this.authentication.subscription)
                return;
            // Grab current subscription from auth
            const currentSubscription = this.authentication.subscription;
            // User already subbed on Apple: Continue to instructions screen
            if (!forceStripe &&
                currentSubscription.is_active &&
                currentSubscription.source === SubscriptionSource.IOS) {
                this.flowState = 'apple';
                return;
            }
            // User already subbed on Google: Continue to instructions screen
            if (!forceStripe &&
                currentSubscription.is_active &&
                currentSubscription.source === SubscriptionSource.ANDROID) {
                this.flowState = 'google';
                return;
            }
            // User already subbed on Stripe: Continue to Billing Portal
            if (currentSubscription.is_active &&
                currentSubscription.source === SubscriptionSource.STRIPE) {
                let response;
                try {
                    response = yield get(this.authentication.host, this.authentication.authToken, `api/subscriptions/2.0/checkout/provider-portal`);
                }
                catch (error) {
                    console.error(error);
                    return;
                }
                window.location.href = response.redirect_url;
                return;
            }
            // Otherwise: Continue to Stripe Checkout
            let response;
            try {
                response = yield post(this.authentication.host, this.authentication.authToken, `api/subscriptions/2.0/checkout`, { price_id: product.default_price.id });
            }
            catch (error) {
                console.error(error);
                return;
            }
            window.location.href = response.redirect_url;
            return;
        });
        // Open
        this._open = () => {
            var _a, _b;
            (_b = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('grain-modal')) === null || _b === void 0 ? void 0 : _b._open();
        };
        // Close
        this._close = () => {
            var _a, _b;
            (_b = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('grain-modal')) === null || _b === void 0 ? void 0 : _b._close();
        };
    }
    // On mount, determine flow state
    connectedCallback() {
        super.connectedCallback();
        this._setFlowState();
    }
    // On authentication context update, determine flow state
    updated(changedProperties) {
        if (!changedProperties.has('flowState')) {
            this._setFlowState();
        }
    }
    render() {
        return html `<grain-modal class="grain-modal-upgrade">
      ${choose(this.flowState, [
            [
                'loading',
                () => {
                    return html `<grain-modal-upgrade-loading
              @close=${() => this._close()}
            />`;
                },
            ],
            [
                'prompt',
                () => {
                    return html `<grain-modal-upgrade-prompt
              .promptDescription=${this.promptDescription}
              @chooseProduct=${(e) => this._chooseProduct(e.detail.product)}
              @close=${() => this._close()}
            />`;
                },
            ],
            [
                'apple',
                () => {
                    return html `<grain-modal-upgrade-apple
              @close=${() => this._close()}
            />`;
                },
            ],
            [
                'google',
                () => {
                    return html `<grain-modal-upgrade-google
              @chooseProduct=${(e) => this._chooseProduct(e.detail.product, true)}
              @close=${() => this._close()}
            />`;
                },
            ],
        ])}
    </grain-modal>`;
    }
};
ModalUpgrade.styles = styles;
__decorate([
    consume({ context: authenticationContext, subscribe: true })
], ModalUpgrade.prototype, "authentication", void 0);
__decorate([
    state()
], ModalUpgrade.prototype, "flowState", void 0);
__decorate([
    property({ type: String })
], ModalUpgrade.prototype, "promptDescription", void 0);
ModalUpgrade = __decorate([
    registerCustomElement('grain-modal-upgrade')
], ModalUpgrade);
export { ModalUpgrade };
