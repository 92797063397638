const CheckboxUnselectedIcon = () => `<svg
  xmlns="http://www.w3.org/2000/svg"
  width="100%"
  height="100%"
  viewBox="0 0 16 17"
  fill="currentColor">
  <g>
    <path
      d="M0.7,2.5c0-0.7,0.6-1.3,1.3-1.3h12c0.7,0,1.3,0.6,1.3,1.3v12c0,0.7-0.6,1.3-1.3,1.3H2c-0.7,0-1.3-0.6-1.3-1.3
    V2.5z M14,2.5H2v12h12V2.5z"
    />
  </g>
</svg>`;
export default CheckboxUnselectedIcon;
