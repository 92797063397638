import { msg } from '@lit/localize';
export function populateOriginalSlotContent(element, originalSlotContent) {
    var _a, _b;
    if (element.nodeType === Node.TEXT_NODE) {
        const originalContent = ((_a = element.textContent) === null || _a === void 0 ? void 0 : _a.trim()) || '';
        if (!originalSlotContent.has(element)) {
            originalSlotContent.set(element, originalContent);
        }
        return;
    }
    if (element instanceof HTMLElement) {
        const originalContent = (_b = element.textContent) === null || _b === void 0 ? void 0 : _b.trim();
        if (!originalSlotContent.has(element) && originalContent && element.children.length === 0) {
            originalSlotContent.set(element, originalContent);
        }
        if (element.tagName.toLowerCase() === 'a' && element.hasAttribute('href')) {
            if (!originalSlotContent.has(element)) {
                originalSlotContent.set(element, element);
            }
        }
        // Recursively populate content for child elements
        element.childNodes.forEach(child => {
            if (child instanceof HTMLElement) {
                populateOriginalSlotContent(child, originalSlotContent);
            }
        });
    }
}
export function updateSlots(shadowRoot, originalSlotContent) {
    const slots = shadowRoot === null || shadowRoot === void 0 ? void 0 : shadowRoot.querySelectorAll('slot');
    slots === null || slots === void 0 ? void 0 : slots.forEach(slot => {
        slot.assignedNodes().forEach(node => {
            if (node.nodeType === Node.TEXT_NODE) {
                const originalContent = originalSlotContent.get(node);
                if (originalContent) {
                    node.textContent = msg(originalContent);
                }
            }
            else if (node instanceof HTMLElement) {
                updateElementContent(node, originalSlotContent);
            }
        });
    });
}
function updateElementContent(element, originalSlotContent) {
    const originalContent = originalSlotContent.get(element);
    if (typeof originalContent === 'string') {
        element.innerHTML = msg(originalContent);
    }
    if (element.tagName.toLowerCase() === 'a' && element.hasAttribute('href')) {
        const originalHref = element.getAttribute('href');
        if (originalHref) {
            element.setAttribute('href', msg(originalHref));
        }
    }
    // Recursively update content for child elements
    element.childNodes.forEach(child => {
        if (child instanceof HTMLElement) {
            updateElementContent(child, originalSlotContent);
        }
    });
}
