const ShareIcon = () => `<svg
  xmlns="http://www.w3.org/2000/svg"
  width="100%"
  height="100%"
  viewBox="0 0 24 24"
  fill="currentColor">
  <path d="M19,22H5c-1.1,0-2-0.9-2-2v-4h2v4h14v-4h2v4C21,21.1,20.1,22,19,22z" />
  <polygon points="13,16 11,16 11,5.4 7.2,9.2 5.8,7.8 12,1.6 18.2,7.8 16.8,9.2 13,5.4 " />
</svg>`;
export default ShareIcon;
