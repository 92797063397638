const AspectRatioAllIcon = () => `<svg
  xmlns="http://www.w3.org/2000/svg"
  width="100%"
  height="100%"
  viewBox="0 0 24 24"
  fill="none">
  <path
    d="M23 1V23H8.01008H1V1L23 1Z"
    stroke="currentColor"
    stroke-width="2"
  />
  <path
    d="M19 1V23H9.34006H5L5 1L19 1Z"
    stroke="currentColor"
    stroke-width="2"
  />
  <path
    d="M23 6V18H8.01008H1V6L23 6Z"
    stroke="currentColor"
    stroke-width="2"
  />
</svg>`;
export default AspectRatioAllIcon;
