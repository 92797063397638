var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { configureLocalization } from '@lit/localize';
import * as en from "./en";
import * as es from "./es";
import * as pt from "./pt";
const linksLocalizations = {
    en: {
        'https://www.vsco.co': 'https://www.vsco.co',
        'https://www.vsco.co/features': 'https://www.vsco.co/features',
        'https://www.vsco.co/features/canvas': 'https://www.vsco.co/features/canvas',
        'https://www.vsco.co/learn': 'https://www.vsco.co/learn',
        'https://www.vsco.co/safety': 'https://www.vsco.co/safety',
        'https://www.vsco.co/vsco-hub': 'https://www.vsco.co/vsco-hub',
        'https://vs.co/download': 'https://vs.co/download',
        'https://vsco.co/subscribe/start': 'https://vsco.co/subscribe/start',
        'https://vsco.co/vsco/journal/p/1': 'https://vsco.co/vsco/journal/p/1',
        'https://support.vsco.co/hc/en-us': 'https://support.vsco.co/hc/en-us',
        'https://support.vsco.co/hc/en-us/community/topics': 'https://support.vsco.co/hc/en-us/community/topics',
        '//{{host}}/{{username}}': '//{{host}}/{{username}}',
        '//{{host}}/feed': '//{{host}}/feed',
        '//{{host}}/search': '//{{host}}/search',
        '//{{host}}/signup': '//{{host}}/signup',
        '//{{host}}/user/account': '//{{host}}/user/account',
        '//{{host}}/user/login': '//{{host}}/user/login',
        '//{{host}}/user/signup': '//{{host}}/user/signup',
    },
    es: {
        'https://www.vsco.co': 'https://www.vsco.co/es-mx',
        'https://www.vsco.co/features': 'https://www.vsco.co/es-mx/features',
        'https://www.vsco.co/features/canvas': 'https://www.vsco.co/features/canvas',
        'https://www.vsco.co/learn': 'https://www.vsco.co/es-mx/learn',
        'https://www.vsco.co/safety': 'https://www.vsco.co/safety',
        'https://www.vsco.co/vsco-hub': 'https://www.vsco.co/es-mx/vsco-hub',
        'https://vs.co/download': 'https://vs.co/es-mx/download',
        'https://vsco.co/subscribe/start': 'https://vsco.co/subscribe/start',
        'https://vsco.co/vsco/journal/p/1': 'https://vsco.co/vsco/journal/p/1',
        'https://support.vsco.co/hc/en-us': 'https://support.vsco.co/hc/en-us',
        'https://support.vsco.co/hc/en-us/community/topics': 'https://support.vsco.co/hc/en-us/community/topics',
        '//{{host}}/{{username}}': '//{{host}}/{{username}}',
        '//{{host}}/feed': '//{{host}}/feed',
        '//{{host}}/search': '//{{host}}/search',
        '//{{host}}/signup': '//{{host}}/signup',
        '//{{host}}/user/account': '//{{host}}/user/account',
        '//{{host}}/user/login': '//{{host}}/user/login',
        '//{{host}}/user/signup': '//{{host}}/user/signup',
    },
    pt: {
        'https://www.vsco.co': 'https://www.vsco.co/pt-br',
        'https://www.vsco.co/features': 'https://www.vsco.co/pt-br/features',
        'https://www.vsco.co/features/canvas': 'https://www.vsco.co/features/canvas',
        'https://www.vsco.co/learn': 'https://www.vsco.co/pt-br/learn',
        'https://www.vsco.co/safety': 'https://www.vsco.co/safety',
        'https://www.vsco.co/vsco-hub': 'https://www.vsco.co/pt-br/vsco-hub',
        'https://vs.co/download': 'https://vs.co/download',
        'https://vsco.co/subscribe/start': 'https://vsco.co/subscribe/start',
        'https://vsco.co/vsco/journal/p/1': 'https://vsco.co/vsco/journal/p/1',
        'https://support.vsco.co/hc/en-us': 'https://support.vsco.co/hc/en-us',
        'https://support.vsco.co/hc/en-us/community/topics': 'https://support.vsco.co/hc/en-us/community/topics',
        '//{{host}}/{{username}}': '//{{host}}/{{username}}',
        '//{{host}}/feed': '//{{host}}/feed',
        '//{{host}}/search': '//{{host}}/search',
        '//{{host}}/signup': '//{{host}}/signup',
        '//{{host}}/user/account': '//{{host}}/user/account',
        '//{{host}}/user/login': '//{{host}}/user/login',
        '//{{host}}/user/signup': '//{{host}}/user/signup',
    }
};
const langPathURLRegex = /(pt-br|es-mx)/i;
const LocalesURLPath = {
    EN: '',
    PT: 'pt-br',
    ES: 'es-mx',
};
export const Locales = {
    EN: 'en',
    PT: 'pt',
    ES: 'es',
};
export const LocalesLabel = {
    [Locales.EN]: 'English',
    [Locales.PT]: 'Português',
    [Locales.ES]: 'Español',
};
const templates = {
    en,
    es,
    pt,
};
const { getLocale, setLocale } = configureLocalization({
    sourceLocale: Locales.EN,
    targetLocales: Object.values(Locales),
    loadLocale: (locale) => __awaiter(void 0, void 0, void 0, function* () {
        return templates[locale];
    })
});
/**
 * Returns the language name based on the language code.
 *
 * @param {string} lang - The language code to get the language name.
 *
 * @returns {string} The language name based on the language code.
 */
const getLanguageName = (lang) => {
    const langLabel = LocalesLabel[lang];
    if (!langLabel) {
        return LocalesLabel[Locales.EN];
    }
    return langLabel;
};
/**
 * Returns the language code based on the language name.
 *
 * @param {string} lang - The language name to get the language code.
 *
 * @returns {Locales} The language code based on the language name.
 */
const getLanguageCode = (lang) => {
    const langKey = (lang.includes('-') ? lang.split('-')[0] : lang).toUpperCase();
    const langCode = Locales[langKey];
    if (!langCode) {
        return Locales.EN;
    }
    return langCode;
};
/**
 * Checks if the URL path already has the language prefix.
 *
 * @param {Languages} lang - The language code to check if it is already prefixed in the URL path.
 * @param {string} path - The URL path to check if it already has the language prefix.
 *
 * @returns {boolean} True if the URL path already has the language prefix; otherwise, false.
 */
const isURLPathAlreadyPrefixed = (lang, path) => {
    const urlPathHasESPTLang = langPathURLRegex.test(path);
    if (lang === Locales.EN) {
        return !urlPathHasESPTLang;
    }
    if (lang === Locales.PT) {
        return path.includes(LocalesURLPath.PT);
    }
    return path.includes(LocalesURLPath.ES);
};
/**
 * Reflects the selected language in the URL by updating the path.
 *
 * Example:
 *
 * - Before: https://www.vsco.co/features
 * - After: https://www.vsco.co/es-mx/features
 *
 * @param {string} lang - The language code to reflect in the URL (e.g., 'en', 'pt', 'es').
 */
const reflectSelectedLanguageInURL = (lang) => {
    var _a;
    const baseUrl = window.location.origin;
    const path = (_a = window.location.pathname.split('/')[1]) !== null && _a !== void 0 ? _a : "";
    if (isURLPathAlreadyPrefixed(lang, path)) {
        return;
    }
    const originalPath = window.location.pathname.replace(/\/?(pt-br|es-mx)\/?/gi, '');
    let newUrl;
    switch (lang) {
        case Locales.PT:
            newUrl = `${LocalesURLPath.PT}${originalPath.length > 1 ? `/${originalPath}` : originalPath}`;
            break;
        case Locales.ES:
            newUrl = `${LocalesURLPath.ES}${originalPath.length > 1 ? `/${originalPath}` : originalPath}`;
            break;
        default:
            newUrl = `${originalPath}`;
    }
    window.location.href = `${baseUrl}/${newUrl.replace(/^\/+|\/+/g, '/')}${window.location.search}`;
};
/**
 * Gets the selected language from the URL path.
 *
 * @returns {Locales} The selected language based on the URL path.
 */
const getSelectedLanguageFromURL = () => {
    var _a;
    const path = window.location.pathname.split('/')[1];
    const lang = path.match(langPathURLRegex);
    switch (((_a = lang === null || lang === void 0 ? void 0 : lang[0]) !== null && _a !== void 0 ? _a : '').toLowerCase()) {
        case LocalesURLPath.PT:
            return Locales.PT;
        case LocalesURLPath.ES:
            return Locales.ES;
        default:
            return Locales.EN;
    }
};
/**
 * Localizes a link based on the current locale.
 * If the link does not have a translation for the current locale, it will return the original link.
 *
 * Example:
 *   localizeLink('https://www.vsco.co/features', 'es') // 'https://www.vsco.co/es-mx/features'
 *   localizeLink('https://www.vsco.co/features', 'pt') // 'https://www.vsco.co/pt-br/features'
 *   localizeLink('https://www.vsco.co/features', 'en') // 'https://www.vsco.co/features'
 *
 * @param link - The link to localize.
 * @returns The localized link.
 */
const localizeLink = (link) => {
    const hasTranslation = linksLocalizations[getLocale()][link];
    return hasTranslation !== null && hasTranslation !== void 0 ? hasTranslation : link;
};
export { getLocale, setLocale, getLanguageCode, getLanguageName, getSelectedLanguageFromURL, localizeLink, reflectSelectedLanguageInURL };
