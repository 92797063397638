const GridIcon = () => `<svg
  width="100%"
  height="100%"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M16.0002 8H13V11L16.0002 11V8ZM16.0002 13L13 13V16H16.0002V13ZM11 11V8H8.0002V11L11 11ZM8.0002 13L11 13V16H8.0002V13ZM6.0002 12.02V16C6.0002 17.1046 6.89563 18 8.0002 18H12H16.0002C17.1048 18 18.0002 17.1046 18.0002 16V8C18.0002 6.89543 17.1048 6 16.0002 6H12H8.0002C6.89563 6 6.0002 6.89543 6.0002 8V11.98C6.00007 11.9867 6 11.9933 6 12C6 12.0067 6.00007 12.0133 6.0002 12.02Z"
    fill="currentColor"
  />
</svg>`;
export default GridIcon;
