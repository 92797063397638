var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement, nothing } from 'lit';
import { property, state } from 'lit/decorators.js';
import { styles } from './navbar-item-styles';
import { registerCustomElement } from '../utils/registerCustomElement';
import { localizeLink } from "../locales/config";
import { localized } from "@lit/localize";
// remove trailing slash from url
function removeTrailingSlash(url) {
    if (url.endsWith('/')) {
        return url.slice(0, -1);
    }
    return url;
}
let NavbarItem = class NavbarItem extends LitElement {
    constructor() {
        super(...arguments);
        this.href = '';
        // Used by isActive logic to determine whether an exact match is necessary to indicate
        // that link is active
        this.exactMatch = false;
        this.icon = '';
        this.id = '';
        this.isExternal = false;
        this.isActive = false;
    }
    connectedCallback() {
        super.connectedCallback();
        /*
          @TODO: implement a more robust test for determining whether location matches this.href
          - add support for checking paths as well as full URLs
        */
        // remove trailing slash from this.href if it has one
        const cleanHref = removeTrailingSlash(window === null || window === void 0 ? void 0 : window.location.href);
        // check if url is https
        if (this.exactMatch && cleanHref === this.href) {
            this.isActive = true;
        }
        else if (!this.exactMatch && (window === null || window === void 0 ? void 0 : window.location.href.includes(this.href))) {
            this.isActive = true;
        }
    }
    render() {
        return html `
      <a
        href=${localizeLink(this.href)}
        class="nav-link--primary"
        id=${this.id}
        target=${this.isExternal ? '_blank' : nothing}
        rel=${this.isExternal ? 'noopener noreferrer' : nothing}
      >
        <div class="nav-icon-container">
          <grain-icon name=${this.icon}></grain-icon>
        </div>
        <slot></slot>
      </a>
    `;
    }
};
NavbarItem.styles = styles;
__decorate([
    property({ type: String })
], NavbarItem.prototype, "href", void 0);
__decorate([
    property({ type: String })
], NavbarItem.prototype, "exactMatch", void 0);
__decorate([
    property({ type: String })
], NavbarItem.prototype, "icon", void 0);
__decorate([
    property({ type: String })
], NavbarItem.prototype, "id", void 0);
__decorate([
    property({ type: String })
], NavbarItem.prototype, "isExternal", void 0);
__decorate([
    state()
], NavbarItem.prototype, "isActive", void 0);
NavbarItem = __decorate([
    localized(),
    registerCustomElement('grain-navbar-item')
], NavbarItem);
export { NavbarItem };
