const CanvasIcon = () => `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.01" width="24" height="24" fill="white" fill-opacity="0.01"/>
    <g clip-path="url(#clip0_4373_4411)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9998 0.749512C12.3101 0.749512 12.5884 0.940633 12.6998 1.23028L15.4968 8.5025L22.769 11.2995C23.0586 11.4109 23.2498 11.6892 23.2498 11.9995C23.2498 12.3098 23.0586 12.5881 22.769 12.6995L15.4968 15.4965L12.6998 22.7687C12.5884 23.0584 12.3101 23.2495 11.9998 23.2495C11.6894 23.2495 11.4111 23.0584 11.2997 22.7687L8.50274 15.4965L1.23052 12.6995C0.940877 12.5881 0.749756 12.3098 0.749756 11.9995C0.749756 11.6892 0.940878 11.4109 1.23052 11.2995L8.50274 8.5025L11.2997 1.23028C11.4111 0.940633 11.6894 0.749512 11.9998 0.749512ZM11.9998 3.58877L9.7831 9.35208C9.70692 9.55015 9.55039 9.70667 9.35232 9.78285L3.58901 11.9995L9.35232 14.2162C9.55039 14.2924 9.70692 14.4489 9.7831 14.6469L11.9998 20.4103L14.2164 14.6469C14.2926 14.4489 14.4491 14.2924 14.6472 14.2162L20.4105 11.9995L14.6472 9.78285C14.4491 9.70667 14.2926 9.55015 14.2164 9.35208L11.9998 3.58877Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_4373_4411">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>`;
export default CanvasIcon;
