import { css } from 'lit';
export const styles = css `
  .title {
    margin: 0;
    font-size: 21px;
    font-weight: 500;
    line-height: 28px;
  }

  .description {
    margin-top: 16px;
  }

  .steps {
    counter-reset: steps 0;
    margin-top: 16px;
  }

  .step {
    counter-increment: steps;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    opacity: 0.5;

    &.on {
      opacity: 1;
    }
  }

  .separator {
    display: block;
    width: 1px;
    height: 26px;
    background-color: var(--grain-color-typography-text-primary);
    margin-left: 17px;
    opacity: 0.5;
  }

  .number {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    border: 1px solid var(--grain-color-typography-text-primary);
  }

  .counter {
    line-height: 1;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;

    &::before {
      content: counter(steps);
    }
  }

  .text {
    margin-left: 8px;
    align-self: center;
  }

  .buttons {
    margin-top: 48px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 16px;

    & > * {
      flex: 1;
      min-width: 0;
      max-width: 50%;
    }
  }
`;
