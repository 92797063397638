const LockIcon = () => `
<svg
  xmlns="http://www.w3.org/2000/svg"
  width="100%"
  height="100%"
  viewBox="0 0 12 12"
  fill="none">
  <path
    fill="currentColor"
    fill-rule="evenodd"
    d="M6 1a3 3 0 0 1 3 3v1h1v6H2V5h1V4a3 3 0 0 1 3-3Zm2 4H4V4a2 2 0 1 1 4 0v1Z"
    clip-rule="evenodd"
    />
</svg>`;
export default LockIcon;
