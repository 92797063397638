const AspectRatio_4_5_Icon = () => `<svg
  xmlns="http://www.w3.org/2000/svg"
  width="100%"
  height="100%"
  viewBox="0 0 24 24"
  fill="none">
  <path
    fill="currentColor"
    d="M23.5,24h-23V0h23V24z M2.5,22h19V2h-19V22z"
  />
</svg>`;
export default AspectRatio_4_5_Icon;
