const DeleteIcon = () => `  <svg
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  width="100%"
  height="100%"
  viewBox="0 0 16 16"
  fill="currentColor">
  <path
    d="M9.3,2V1.3C9.3,1,9,0.7,8.7,0.7H7.3C7,0.7,6.7,1,6.7,1.3V2H1.3v1.3h1.4l0.6,9.5c0.1,1.1,0.9,1.9,2,1.9h5.5
    c1.1,0,1.9-0.8,2-1.9l0.6-9.5h1.4V2H9.3z M11.4,12.7c0,0.4-0.3,0.6-0.7,0.6H5.3c-0.4,0-0.6-0.3-0.7-0.6L4,3.3H12L11.4,12.7z"
  />
</svg>`;
export default DeleteIcon;
