const AddPlusCircleInvertIcon = () => `<svg
  xmlns="http://www.w3.org/2000/svg"
  width="100%"
  height="100%"
  viewBox="0 0 24 24"
  fill="none">
  <mask id="path-1-inside-1_2244_419">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM13 11H18V13H13V18H11V13H6V11H11V6H13V11Z"
    />
  </mask>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM13 11H18V13H13V18H11V13H6V11H11V6H13V11Z"
    fill="currentColor"
  />
  <path
    d="M18 11H20V9H18V11ZM13 11H11V13H13V11ZM18 13V15H20V13H18ZM13 13V11H11V13H13ZM13 18V20H15V18H13ZM11 18H9V20H11V18ZM11 13H13V11H11V13ZM6 13H4V15H6V13ZM6 11V9H4V11H6ZM11 11V13H13V11H11ZM11 6V4H9V6H11ZM13 6H15V4H13V6ZM12 26C19.732 26 26 19.732 26 12H22C22 17.5228 17.5228 22 12 22V26ZM-2 12C-2 19.732 4.26801 26 12 26V22C6.47715 22 2 17.5228 2 12H-2ZM12 -2C4.26801 -2 -2 4.26801 -2 12H2C2 6.47715 6.47715 2 12 2V-2ZM26 12C26 4.26801 19.732 -2 12 -2V2C17.5228 2 22 6.47715 22 12H26ZM18 9H13V13H18V9ZM20 13V11H16V13H20ZM13 15H18V11H13V15ZM11 13V18H15V13H11ZM13 16H11V20H13V16ZM13 18V13H9V18H13ZM6 15H11V11H6V15ZM4 11V13H8V11H4ZM11 9H6V13H11V9ZM13 11V6H9V11H13ZM11 8H13V4H11V8ZM11 6V11H15V6H11Z"
    fill="currentColor"
    mask="url(#path-1-inside-1_2244_419)"
  />
</svg>`;
export default AddPlusCircleInvertIcon;
