import { css } from 'lit';
export const styles = css `
  .grain-icon {
    display: inline-block;
    vertical-align: middle;
    font-size: 0;
  }

  .grain-icon-svg {
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 100%;
  }

  /* inverted prop */
  .is-inverted {
    .grain-icon-svg {
      filter: invert(1);
    }
  }
`;
