var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from 'lit';
import { property } from 'lit/decorators.js';
import { registerCustomElement } from '../utils/registerCustomElement';
let Entitlement = class Entitlement extends LitElement {
    constructor() {
        super(...arguments);
        this.isLoggedIn = false;
        this.isPro = false;
        this.isPlus = false;
        this.isStarter = false;
        this.username = '';
        this.host = 'vsco.co';
    }
    // Children
    render() {
        return html `<slot></slot>`;
    }
};
__decorate([
    property({ type: Boolean })
], Entitlement.prototype, "isLoggedIn", void 0);
__decorate([
    property({ type: Boolean })
], Entitlement.prototype, "isPro", void 0);
__decorate([
    property({ type: Boolean })
], Entitlement.prototype, "isPlus", void 0);
__decorate([
    property({ type: Boolean })
], Entitlement.prototype, "isStarter", void 0);
__decorate([
    property({ type: String })
], Entitlement.prototype, "username", void 0);
__decorate([
    property({ type: String })
], Entitlement.prototype, "host", void 0);
Entitlement = __decorate([
    registerCustomElement('grain-entitlement')
], Entitlement);
export { Entitlement };
/*
  Take props passed to Entitlement
  & hydrate data

  example: username, host, etc
*/
export const hydrateEntitlement = (input, instance) => {
    let output = input;
    // Grab {{vars}} from input
    const matches = input.match(/{{([a-zA-Z]+)}}/gmi);
    if (!matches)
        return output;
    // For every match, replace it with the instance's value
    for (const match of matches) {
        const sanitized = match.replace('{{', '').replace('}}', '');
        if (!(sanitized in instance))
            continue;
        output = output.replace(match, instance[sanitized]);
    }
    // Return the final replaced string
    return output;
};
