// Do not modify this file by hand!
// Re-generate this file by running lit-localize
import { str } from '@lit/localize';
/* eslint-disable no-irregular-whitespace */
/* eslint-disable @typescript-eslint/no-explicit-any */
export const templates = {
    's02a128deecd76592': `https://www.vsco.co/vsco-hub`,
    's077cc274692ed6d7': `Photography Basics`,
    's0e2e9cd0c7ba2c51': `SIGN UP`,
    's13a2e3afa0779b53': `Plans`,
    's19b10d1a8593ca55': `Learn`,
    's1e48e09152f88c54': `https://www.vsco.co/features/community`,
    's212189a6300414ec': `Photo Editor`,
    's21c4b4ad03e05269': `https://www.vsco.co/features/photo-editor`,
    's239a3851e1d50b92': `Photography Business`,
    's2c362b854bbf7590': `Cookie Settings`,
    's3273484a78b97574': `Curated Photo Collections`,
    's3736464cf8d6fa08': `Support`,
    's4653b68c582adb73': `What's New`,
    's488d181316931103': `https://www.vsco.co/features/photo-filters`,
    's494936b21828660d': `Creative Community`,
    's498a0a19cdcb610c': `HUB`,
    's4caed5b7a7e5d89b': `English`,
    's5105e385c839fa25': `FEED`,
    's54b80acebd96daf2': `https://www.vsco.co/about#press`,
    's56750e27217d1955': `LEARN`,
    's592ec97ab73e8018': `Careers`,
    's5e6b698a2ec87331': `Sign up`,
    's6027cbc671d62950': `LOG IN`,
    's65c3e033b5506816': `Your Privacy Choices`,
    's6a0bc0ce56a9ae4a': `Features`,
    's6b1ffa415c97de0b': `Photographer Stories`,
    's71aaed0b53728ff5': `So you can make it`,
    's77aa17839291ed94': `VSCO Canvas`,
    's7cb4e55c59663b1c': `Company`,
    's7f724590eb8abf99': `https://vsco.co/subscribe/start`,
    's82a0d4177a41ba60': `https://vs.co/download`,
    's8642948ac334e994': `Press`,
    's8cdd9e134d0cc5a1': `Try for free`,
    's91448dcac4812328': `Guides`,
    's977fac999ad9348a': `https://www.vsco.co/about`,
    's98484c2fa289e12e': `PROFILE`,
    's9fc41f789c63b40d': `Products`,
    'sa13e778658f3d8d1': `https://www.vsco.co/learn`,
    'sa384dc1622f82116': `Forum`,
    'sa4858f3708bed642': `Português (Brasil)`,
    'sab07cf2bfae8483f': `Privacy Policy`,
    'sb02d8eb5c14ee92f': `https://www.vsco.co/features/canvas`,
    'sb4de79af88ea14c6': `Terms of Use`,
    'sba1baaecc4139243': str `Copyright
                    ${0}
                    VSCO. All rights reserved.
                `,
    'sc2052449831b2c5c': `ACCOUNT`,
    'sc734b76bcb9a53e7': `Safety`,
    'sc8229c1b326021a9': `Photography Guides`,
    'scb04435672ac5410': `Log in`,
    'scc26734c8f017a51': `CANVAS`,
    'sd0864ea6cb6ef22e': `Community`,
    'sd4e5e0509cc3c976': `Guidelines`,
    'sd596ce4ac14a69b7': `Get the app`,
    'sdb2e2df51e9c41d7': `About VSCO`,
    'sdba658848635c0f5': `Download now`,
    'sddfcf9978e525329': `SEARCH`,
    'se0c38e02bbf8af7e': `https://www.vsco.co/features`,
    'se4917d2c7e671e08': `Photo Filters`,
    'se75befb41b923e54': `Español (México)`,
    'secdad4ae080e9927': `VSCO Hub`,
    'sf01935bab18089dc': `Mobile App`,
    'sf3c2aace5830b503': `STUDIO`,
    'sf492a976b44e1fc7': `VSCO Hub Agreement`,
};
