const PlusIcon = () => `<svg
  xmlns="http://www.w3.org/2000/svg"
  width="100%"
  height="100%"
  viewBox="0 0 16 17"
  fill="none">
  <rect
    x="7.33333"
    y="1.33624"
    width="1.33333"
    height="13.3333"
    fill="currentColor"
  />
  <rect
    x="1.33333"
    y="8.66962"
    width="1.33333"
    height="13.3333"
    transform="rotate(-90 1.33333 8.66962)"
    fill="currentColor"
  />
</svg>`;
export default PlusIcon;
