import { css } from 'lit';
export const styles = css `
    :host {
        /*
          @TODO:
          Add these tokens to component-based tokens
          in Figma plug-in
        */
        --footer-surface: var(--grain-global-color-black-100);
        --footer-logo-fill: var(--grain-global-color-white-100);
        --footer-headline-color: var(--grain-global-color-white-100);
        --footer-index-link-color: var(--grain-global-color-white-100);
        --footer-copyright-color: var(--grain-global-color-gray-300);
        --footer-terms-link-color: var(--grain-global-color-gray-300);
        --footer-divider-color: var(--grain-global-color-gray-850);
    }

    .grain-footer {
        container-type: inline-size;
    }

    .footer-wrapper {
        background-color: var(--footer-surface);
        padding: 80px 40px 32px 40px;
    }

    .footer-wrapper-condensed {
        background-color: var(--footer-surface);
        padding: 32px 40px 32px 40px;
    }

    .footer-content {
        display: flex;
        flex-direction: column;
    }

    ul {
        /* browser reset */
        padding-inline: 0;
        margin-block: 0;
        list-style: none;
    }

    ::slotted(ul) {
        /* browser reset */
        padding-inline: 0;
        margin-block: 0;
        list-style: none;
    }

    .footer-logo {
        display: inline-block;
        margin-block-end: 40px;
    }

    .footer-masthead {
        display: none;
    }

    .footer-headline {
        margin-block-end: 60px;
    }

    .footer-headline-text {
        margin-block-start: 0;
        margin-block-end: 0;
        color: var(--footer-headline-color);
        font-weight: 500;
        font-size: 30px;
        text-align: justify;
        text-transform: uppercase;
        line-height: 28px;
    }

    .footer-engagement-item {
        &:not(:last-child) {
            margin-block-end: 24px;
        }
    }

    .footer-identity {
        margin-block-end: 60px;
    }

    .footer-index-headline {
        margin-block-start: 0;
        margin-block-end: 24px;
        color: var(--footer-headline-color);
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
        /* @TODO: Setup letter-spacing values in tokens */
        letter-spacing: .1em; /* 10% */
    }

    .footer-index-headline {
        & a {
            color: var(--footer-headline-color);
            text-decoration: none;
        }
    }

    .footer-index-list-item {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
    }

    .footer-index-list-item {
        & a {
            color: var(--footer-index-link-color);
            text-decoration: none;
        }

        & a:hover {
            text-decoration: underline;
        }
    }

    .footer-index-list-item {
        &:not(:last-child) {
            margin-block-end: 16px;
        }
    }

    .footer-terms {
        margin-block-end: 40px;
    }

    .footer-terms-list-item {
        & a {
            font-size: 13px;
            font-weight: 400;
            line-height: 16px;
            color: var(--footer-terms-link-color);
            text-decoration: none;
        }

        & a:hover {
            text-decoration: underline;
        }
    }

    .footer-terms-list-item {
        &:not(:last-child) {
            margin-block-end: 24px;
        }
    }

    .footer-index-section {
        &:not(:last-child) {
            margin-block-end: 40px;
        }
    }

    .footer-divider {
        display: block;
        margin-block-start: 60px;
        margin-block-end: 60px;
        height: 1px;
        background-color: var(--footer-divider-color);
    }

    .footer-copyright-text {
        margin-block: 0;
        color: var(--footer-copyright-color);
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
    }

    .one-trust-icon {
        display: inline-block;
        margin-inline-end: 8px;
    }

    .one-trust-anchor {
        &.is-hidden {
            visibility: hidden;
        }
    }

    .footer-language-selector {
        position: relative;
        display: inline-block;
    }

    .dropdown-button {
        min-width: 90px;
        background-color: var(--footer-surface);
        color: var(--grain-global-color-white-100);
        border: none;
        padding-left: 0;
        font-family: 'VSCO Gothic', var(--grain-global-fontFamily-systemFont), serif;
        font-weight: 400;
        font-size: 13px;
        line-height: 15.6px;
        letter-spacing: 0;
        text-align: left;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .dropdown-icon {
        color: var(--footer-terms-link-color);
    }

    .dropdown-menu {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: var(--grain-global-color-gray-900);
        z-index: 999;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        list-style: none;
        margin: 0;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    }

    [aria-expanded="true"] + .dropdown-menu {
        display: block;
    }

    .dropdown-option {
        display: flex;
        width: 228px;
        min-width: 120px;
        padding: 16px;
        justify-content: space-between;
        align-items: center;
        font-family: 'VSCO Gothic', var(--grain-global-fontFamily-systemFont), serif;
        font-weight: 400;
        font-size: 13px;
        line-height: 15.6px;
        letter-spacing: 0;
        color: var(--grain-global-color-white-100);
        cursor: pointer;
        transition: background-color 0.2s ease;
    }

    .dropdown-option:hover {
        background-color: var(--grain-global-color-gray-850);
        color: var(--grain-global-color-white-100);
    }

    @container (width > 850px) {
        .footer-primary {
            order: 1;
        }

        .footer-index {
            margin-block-end: 18px;
        }

        .footer-index-section {
            &:not(:last-child) {
                margin-block-end: initial;
            }
        }

        .footer-identity {
            display: flex;
            order: 2;
            margin-block-start: 40px;
            margin-block-end: initial;
        }

        .footer-logo {
            margin-block-end: initial;
        }

        .footer-engagement {
            margin-left: auto;
        }

        .footer-engagement-list {
            display: flex;
        }

        .footer-engagement-item {
            &:not(:last-child) {
                margin-block-end: initial;
                margin-inline-end: 24px;
            }
        }

        .footer-divider {
            order: 3;
            margin-block: 40px;
        }

        .footer-secondary {
            order: 4;
        }

        .footer-index {
            display: grid;
            grid-template-columns: repeat(4, minmax(0, 1fr));
            grid-gap: 20px;
            margin-left: auto;
        }

        .footer-legal {
            display: flex;
        }

        .footer-terms {
            margin-block-end: initial;
        }

        .footer-terms-list {
            display: flex;
        }

        .footer-terms-list-item {
            margin-inline-end: 20px;
        }

        .footer-terms-list-item {
            &:not(:last-child) {
                margin-block-end: initial;
            }
        }

        .footer-copyright {
            margin-left: auto;
        }

        .footer-language-selector {
            justify-content: flex-start; /* Match index alignment */
        }
    }

    @container (width > 990px) {
        .footer-masthead {
            display: block;
        }

        .footer-primary-wrapper {
            display: flex;
        }

        .footer-headline-text {
            font-size: 54px;
            line-height: 50px;
            margin-inline-end: 75px;
            max-width: 345px;
        }
    }

    @container (width > 1440px) {
        .footer-headline-text {
            max-width: 465px;
        }
    }

    @media (max-width: 767px) {
        .dropdown-option,
        .dropdown-button {
            font-family: 'VSCO Gothic', var(--grain-global-fontFamily-systemFont), serif;
            font-size: 15px;
            line-height: 18px;
        }
    }
`;
