var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, html } from 'lit';
import { property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { styles } from './styles';
import { registerCustomElement } from '../utils/registerCustomElement';
import { localized } from "@lit/localize";
import { localizeLink } from "../locales/config";
// remove trailing slash from url
function removeTrailingSlash(url) {
    if (url.endsWith('/')) {
        return url.slice(0, -1);
    }
    return url;
}
let NavLink = class NavLink extends LitElement {
    constructor() {
        super(...arguments);
        this.href = '';
        // Used by isActive logic to determine whether an exact match is necessary to indicate
        // that link is active
        this.exactMatch = false;
        this.icon = '';
        this.id = '';
        this.isActive = false;
    }
    connectedCallback() {
        super.connectedCallback();
        /*
          @TODO: implement a more robust test for determining whether location matches this.href
          - add support for checking paths as well as full URLs
        */
        // remove trailing slash from this.href if it has one
        const cleanHref = removeTrailingSlash(window === null || window === void 0 ? void 0 : window.location.href);
        // check if url is https
        if (this.exactMatch && cleanHref === this.href) {
            this.isActive = true;
        }
        else if (!this.exactMatch && (window === null || window === void 0 ? void 0 : window.location.href.includes(this.href))) {
            this.isActive = true;
        }
    }
    render() {
        const classes = { 'is-active': this.isActive };
        return html `
      <a 
        href=${localizeLink(this.href)} 
        class=${classMap(classes)}
        id=${this.id}
      >
        <div class="icon-container">
          <grain-icon name=${this.icon}></grain-icon>
        </div>
        <slot></slot>
      </a>
    `;
    }
};
NavLink.styles = styles;
__decorate([
    property({ type: String })
], NavLink.prototype, "href", void 0);
__decorate([
    property({ type: String })
], NavLink.prototype, "exactMatch", void 0);
__decorate([
    property({ type: String })
], NavLink.prototype, "icon", void 0);
__decorate([
    property({ type: String })
], NavLink.prototype, "id", void 0);
__decorate([
    state()
], NavLink.prototype, "isActive", void 0);
NavLink = __decorate([
    localized(),
    registerCustomElement('grain-nav-link')
], NavLink);
export { NavLink };
