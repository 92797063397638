import { css } from 'lit';
export const styles = css `
  .nav-link--primary {
    display: flex;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    text-decoration: none;
    color: white;
  }

  .nav-icon-container {
    margin-right: 18px;
  }
`;
