var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html } from 'lit';
import { choose } from 'lit/directives/choose.js';
import { styles } from './styles';
import { registerCustomElement } from '../utils/registerCustomElement';
import '../Icon';
import '../Button';
import '../NavLink';
import { Entitlement, hydrateEntitlement } from '../Entitlement';
import { localized, msg } from '@lit/localize';
import { localizeLink } from '../locales/config';
import { property } from 'lit/decorators.js';
let Sidebar = class Sidebar extends Entitlement {
    constructor() {
        super(...arguments);
        this.variant = 'wide';
        // instead of 'left' & 'right' for RTL languages, etc
        this.isInlineStart = true;
        this.isInlineEnd = false;
        this.loginReqStatus = 'idle';
        this.canvasNavEnabled = false;
    }
    render() {
        let uiState = getUiState(this.loginReqStatus, this.isLoggedIn);
        const canvasNavItem = this.canvasNavEnabled
            ? html `
          <grain-nav-link
            href=${hydrateEntitlement(localizeLink('//canvas.{{host}}'), this)}
            icon="canvas"
            id="sidebar-canvas"
          >
            <span class="nav-text">${msg('CANVAS')}</span>
            <grain-icon
              fill="white"
              width="16px"
              height="16px"
              name="external"
              class="icon-external"
            ></grain-icon>
          </grain-nav-link>
        `
            : null;
        return html `
      <div class="sidebar ${this.variant == 'wide' ? 'is-wide' : 'is-narrow'}">
        <div class="logo">
          <a href="${localizeLink('https://www.vsco.co')}">
            ${this.variant === 'wide'
            ? html `<grain-icon
                  name="vscoWordmark"
                  width="86px"
                  height="40px"
                  class="vsco-logo"
                ></grain-icon>`
            : html `<grain-icon
                  name="vscoLogo"
                  width="28px"
                  height="28px"
                  class="vsco-logo"
                ></grain-icon>`}
          </a>
        </div>

        <nav class="nav-link-container">
          <!-- FEED -->
          <grain-nav-link
            href=${hydrateEntitlement(localizeLink('//{{host}}/feed'), this)}
            exactMatch="true"
            icon="explore"
            id="sidebar-feed"
          >
            <span class="nav-text">${msg('FEED')}</span>
          </grain-nav-link>

          <!-- STUDIO -->
          <grain-nav-link
            href=${hydrateEntitlement(localizeLink('//studio.{{host}}'), this)}
            icon="studio"
            id="sidebar-studio"
          >
            <span class="nav-text">${msg('STUDIO')}</span>
            <grain-icon
              fill="white"
              width="16px"
              height="16px"
              name="external"
              class="icon-external"
            ></grain-icon>
          </grain-nav-link>

          <!-- CANVAS -->
          ${canvasNavItem}

          <!-- PROFILE -->
          <grain-nav-link
            href=${!this.isLoggedIn
            ? hydrateEntitlement(localizeLink('//{{host}}/user/signup'), this)
            : hydrateEntitlement(localizeLink('//{{host}}/{{username}}'), this)}
            icon="profile"
            id="sidebar-profile"
          >
            <span class="nav-text">${msg('PROFILE')}</span>
          </grain-nav-link>

          <!-- SEARCH -->
          <grain-nav-link
            href=${hydrateEntitlement(localizeLink('//{{host}}/search'), this)}
            icon="search"
            id="sidebar-search"
          >
            <span class="nav-text">${msg('SEARCH')}</span>
          </grain-nav-link>

          <!-- LEARN -->
          <grain-nav-link
            href="${localizeLink('https://www.vsco.co/learn')}"
            icon="learn"
            id="sidebar-learn"
          >
            <span class="nav-text">${msg('LEARN')}</span>
          </grain-nav-link>
        </nav>

        <nav class="nav-link-container nav-link-container--hub">
          <!-- HUB -->
          <grain-nav-link
            href=${hydrateEntitlement(localizeLink('https://www.vsco.co/vsco-hub/hire'), this)}
            icon="hub"
            id="sidebar-hub"
          >
            <span class="nav-text">${msg('HUB')}</span>
            <grain-icon
              fill="white"
              width="16px"
              height="16px"
              name="external"
              class="icon-external"
            ></grain-icon>
          </grain-nav-link>
        </nav>

        <div class="content">
          ${choose(uiState, [
            ['loading', () => null],
            [
                'loggedOut',
                () => html ` <div class="is-logged-out">
                <span class="content-logged-out-item">
                  <grain-button
                    as="a"
                    href=${hydrateEntitlement(localizeLink('//{{host}}/user/signup'), this)}
                    variant="primary"
                    id="sidebar-loggedOut-sign-up"
                    fullWidth
                  >
                    ${msg('SIGN UP')}
                  </grain-button>
                </span>
                <span class="content-logged-out-item">
                  <grain-button
                    as="a"
                    href=${hydrateEntitlement(localizeLink('//{{host}}/user/login'), this)}
                    variant="secondary"
                    id="sidebar-loggedOut-log-in"
                    fullWidth
                  >
                    ${msg('LOG IN')}
                  </grain-button>
                </span>
              </div>`,
            ],
            [
                'loggedIn',
                () => html ` <div class="is-logged-in">
                <grain-nav-link
                  href=${hydrateEntitlement(localizeLink('//{{host}}/user/account'), this)}
                  icon="gear"
                  id="sidebar-loggedIn-account"
                >
                  <span class="nav-text">${msg('ACCOUNT')}</span>
                </grain-nav-link>
              </div>`,
            ],
        ])}
        </div>
      </div>
    `;
    }
};
Sidebar.styles = styles;
__decorate([
    property({ type: String })
], Sidebar.prototype, "variant", void 0);
__decorate([
    property({ type: Boolean })
], Sidebar.prototype, "isInlineStart", void 0);
__decorate([
    property({ type: Boolean })
], Sidebar.prototype, "isInlineEnd", void 0);
__decorate([
    property({ type: String, attribute: 'login-req-status' })
], Sidebar.prototype, "loginReqStatus", void 0);
__decorate([
    property({ type: Boolean })
], Sidebar.prototype, "canvasNavEnabled", void 0);
Sidebar = __decorate([
    localized(),
    registerCustomElement('grain-sidebar')
], Sidebar);
export { Sidebar };
function getUiState(loginReqStatus, isLoggedIn) {
    let result = 'idle';
    // @TODO: add support for loginReqStatus === 'failed'
    if (loginReqStatus === 'succeeded' && isLoggedIn) {
        result = 'loggedIn';
    }
    else if (loginReqStatus === 'succeeded' && !isLoggedIn) {
        result = 'loggedOut';
    }
    else if (loginReqStatus === 'loading') {
        result = 'loading';
    }
    return result;
}
function getCanvasUrl(isLoggedIn) { }
