const CommentsIcon = () => `<svg
  width="100%"
  height="100%"
  viewBox="0 0 24 24"
  fill="currentColor"
  xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clip-rule="evenodd" d="M17 11H7V9H17V11Z" />
  <path fillRule="evenodd" clip-rule="evenodd" d="M14 15H7V13H14V15Z" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12V21H12ZM1 12C1 18.0751 5.92487 23 12 23H23V12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12Z"
  />
</svg>`;
export default CommentsIcon;
