import { css } from 'lit';
export const styles = css `
  * {
    box-sizing: border-box;
  }

  .grain-modal {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    z-index: 9999;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 32px;
    color: var(--grain-color-typography-text-primary);
    font-size: 13px;
    line-height: 17px;
  }

  .scrim {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: color-mix(
      in srgb,
      var(--grain-global-color-background-1),
      transparent 50%
    );
    z-index: 1;
  }

  .content {
    display: block;
    background-color: var(--grain-global-color-background-1);
    width: 100%;
    max-width: 500px;
    position: relative;
    margin: auto;
    padding: 16px;
    z-index: 2;
  }
`;
