const FlipHorizontal = () => `<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" fill="none">
  <g clip-path="url(#clip0_226_80915)">
    <path d="M23 17.7553L16.5795 12.5039L23 7.25249L23 17.7553Z" stroke="currentColor" stroke-width="2"/>
    <path d="M1 7.25249L7.4205 12.5039L0.999999 17.7553L1 7.25249Z" stroke="currentColor" stroke-width="2"/>
    <line x1="12" y1="0.337891" x2="12" y2="23.3379" stroke="currentColor" stroke-width="2"/>
  </g>
  <defs>
    <clipPath id="clip0_226_80915">
      <rect width="24" height="24" fill="white" transform="translate(24 0.00390625) rotate(90)"/>
    </clipPath>
  </defs>
</svg>`;
export default FlipHorizontal;
