const FlipVertical = () => `<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" fill="none">
  <g clip-path="url(#clip0_226_80911)">
    <path d="M17.0854 1.00391L11.834 7.42441L6.58257 1.00391L17.0854 1.00391Z" stroke="currentColor" stroke-width="2"/>
    <path d="M6.58257 23.0039L11.834 16.5834L17.0854 23.0039H6.58257Z" stroke="currentColor" stroke-width="2"/>
    <line x1="0.333984" y1="12.0039" x2="23.334" y2="12.0039" stroke="currentColor" stroke-width="2"/>
  </g>
  <defs>
    <clipPath id="clip0_226_80911">
      <rect width="24" height="24" fill="white" transform="translate(0 0.00390625)"/>
    </clipPath>
  </defs>
</svg>`;
export default FlipVertical;
