const PasteIcon = () => ` <svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
  width="100%"
  height="100%"
  fill="currentColor">
  <path
    d="M21,7h-4V3c0-1.1-0.9-2-2-2H3C1.9,1,1,1.9,1,3v12c0,1.1,0.9,2,2,2h4v4c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V9
C23,7.9,22.1,7,21,7z M3,15L3,3h0h12v4H9C7.9,7,7,7.9,7,9v6H3z"
  />
</svg>
    `;
export default PasteIcon;
