import { css } from 'lit';
export const styles = css `
  .title {
    margin: 0;
    font-size: 21px;
    font-weight: 500;
    line-height: 28px;
    color: var(--grain-color-membership-pro);
  }

  .description {
    margin-top: 32px;
    font-size: 13px;
    line-height: 17px;
  }

  .features {
    margin-top: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: nowrap;
    flex-direction: column;
    gap: 16px;
  }
  .features .feature {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 8px;
  }
  .features .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
  }
  .features .text {
    flex: 1;
    min-width: 0;
    font-size: 13px;
    font-weight: 500;
    line-height: 17px;
    align-self: center;
  }

  .buttons {
    margin-top: 48px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 16px;
  }
  .buttons > * {
    flex: 1;
    min-width: 0;
  }
  .buttons .claim {
    font-size: 11px;
    font-weight: 500;
    line-height: 14px;
    color: var(--grain-color-typography-text-tertiary);
    margin-top: 8px;
  }

  .disclaimer {
    margin-top: 16px;
  }
  .disclaimer:empty {
    display: none;
  }
  .disclaimer p {
    font-size: 11px;
    font-weight: 500;
    line-height: 14px;
    color: var(--grain-color-typography-text-tertiary);
    margin: 0;
  }
`;
