// Do not modify this file by hand!
// Re-generate this file by running lit-localize
import { str } from '@lit/localize';
/* eslint-disable no-irregular-whitespace */
/* eslint-disable @typescript-eslint/no-explicit-any */
export const templates = {
    's02a128deecd76592': `https://www.vsco.co/pt-br/vsco-hub`,
    's077cc274692ed6d7': `Noções básicas de fotografia`,
    's0e2e9cd0c7ba2c51': `INSCREVER-SE`,
    's13a2e3afa0779b53': `Planos`,
    's19b10d1a8593ca55': `Aprender`,
    's1e48e09152f88c54': `https://www.vsco.co/pt-br/features/community`,
    's212189a6300414ec': `Editor de fotos`,
    's21c4b4ad03e05269': `https://www.vsco.co/pt-br/features/photo-editor`,
    's239a3851e1d50b92': `Negócios de fotografia`,
    's2c362b854bbf7590': `Configurações de cookies`,
    's3273484a78b97574': `Coleções de fotos selecionadas`,
    's3736464cf8d6fa08': `Suporte`,
    's4653b68c582adb73': `O que há de novo`,
    's488d181316931103': `https://www.vsco.co/pt-br/features/photo-filters`,
    's494936b21828660d': `Comunidade criativa`,
    's498a0a19cdcb610c': `HUB`,
    's4caed5b7a7e5d89b': `English`,
    's5105e385c839fa25': `FEED`,
    's54b80acebd96daf2': `https://www.vsco.co/pt-br/about#press`,
    's56750e27217d1955': `APRENDER`,
    's592ec97ab73e8018': `Carreiras`,
    's5e6b698a2ec87331': `Inscrever-se`,
    's6027cbc671d62950': `ENTRAR`,
    's65c3e033b5506816': `Suas escolhas de privacidade`,
    's6a0bc0ce56a9ae4a': `Recursos`,
    's6b1ffa415c97de0b': `Histórias de fotógrafos`,
    's71aaed0b53728ff5': `Então você pode fazer isso`,
    's77aa17839291ed94': `Canvas VSCO`,
    's7cb4e55c59663b1c': `Empresa`,
    's7f724590eb8abf99': `https://vsco.co/subscribe/start`,
    's82a0d4177a41ba60': `https://vs.co/download`,
    's8642948ac334e994': `Imprensa`,
    's8cdd9e134d0cc5a1': `Experimente grátis`,
    's91448dcac4812328': `Guias`,
    's977fac999ad9348a': `https://www.vsco.co/pt-br/about`,
    's98484c2fa289e12e': `PERFIL`,
    's9fc41f789c63b40d': `Produtos`,
    'sa13e778658f3d8d1': `https://www.vsco.co/pt-br/learn`,
    'sa384dc1622f82116': `Fórum`,
    'sa4858f3708bed642': `Português (Brasil)`,
    'sab07cf2bfae8483f': `Política de Privacidade`,
    'sb02d8eb5c14ee92f': `https://www.vsco.co/pt-br/features/canvas`,
    'sb4de79af88ea14c6': `Termos de Uso`,
    'sba1baaecc4139243': str `Copyright
                    ${0}
                    VSCO. Todos os direitos reservados.
                `,
    'sc2052449831b2c5c': `CONTA`,
    'sc734b76bcb9a53e7': `Segurança`,
    'sc8229c1b326021a9': `Guias de fotografia`,
    'scb04435672ac5410': `Entrar`,
    'scc26734c8f017a51': `CANVAS`,
    'sd0864ea6cb6ef22e': `Comunidade`,
    'sd4e5e0509cc3c976': `Diretrizes`,
    'sd596ce4ac14a69b7': `Obtenha o aplicativo`,
    'sdb2e2df51e9c41d7': `Sobre a VSCO`,
    'sdba658848635c0f5': `Baixe agora`,
    'sddfcf9978e525329': `PESQUISAR`,
    'se0c38e02bbf8af7e': `https://www.vsco.co/pt-br/features`,
    'se4917d2c7e671e08': `Filtros de fotos`,
    'se75befb41b923e54': `Español (México)`,
    'secdad4ae080e9927': `Hub VSCO`,
    'sf01935bab18089dc': `Aplicativo móvel`,
    'sf3c2aace5830b503': `STUDIO`,
    'sf492a976b44e1fc7': `Acordo do Hub VSCO`,
};
