const AdjustIcon = () => `<svg
xmlns="http://www.w3.org/2000/svg"
viewBox="0 0 24 24"
width="100%"
height="100%"
fill="currentColor">
<polygon points="7,1 5,1 5,15 7,13 " />
<polygon points="9,4 16,4 14,6 9,6 " />
<rect x="1" y="4" width="2" height="2" />
<polygon points="19,9 19,15 17,15 17,11 " />
<rect x="17" y="21" width="2" height="2" />
<polygon points="23,19 23,17 11,17 9,19 " />
<rect
  x="14"
  y="7.5"
  transform="matrix(0.7071 -0.7071 0.7071 0.7071 -1.5084 13.4293)"
  width="2.9"
  height="2"
/>
<rect
  x="10.5"
  y="11.1"
  transform="matrix(0.7071 -0.7071 0.7071 0.7071 -5.0439 11.965)"
  width="2.9"
  height="2"
/>
<rect
  x="17.5"
  y="4"
  transform="matrix(0.7071 -0.7071 0.7071 0.7071 2.0271 14.894)"
  width="2.9"
  height="2"
/>
<rect
  x="6.9"
  y="14.6"
  transform="matrix(0.7071 -0.7071 0.7071 0.7071 -8.5795 10.5007)"
  width="2.9"
  height="2"
/>
<rect
  x="3.4"
  y="18.1"
  transform="matrix(0.7071 -0.7071 0.7071 0.7071 -12.115 9.036)"
  width="2.9"
  height="2"
/>
</svg>`;
export default AdjustIcon;
