var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { provide } from '@lit/context';
import { LitElement, html } from 'lit';
import { property } from 'lit/decorators.js';
import { get } from '../utils/apiService';
import { registerCustomElement } from '../utils/registerCustomElement';
import { authenticationContext } from './context';
let Authentication = class Authentication extends LitElement {
    constructor() {
        super(...arguments);
        // Auth token passed in via prop
        this.authToken = '';
        // Host passed in via prop
        this.host = '';
        // Provide user object to authenticationContext
        this.authentication = {
            host: '',
            authToken: '',
            reqStatus: 'idle',
            user: {},
            subscription: {},
            products: [],
        };
    }
    // Fetch user info
    updated(changedProperties) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.authToken && this.host) {
                // Set loading state
                this.authentication = Object.assign(Object.assign({}, this.authentication), { host: this.host, authToken: this.authToken, reqStatus: 'loading' });
                // Fetch the user
                let userResponse;
                try {
                    userResponse = yield get(this.host, this.authToken, `api/2.0/users`);
                }
                catch (error) {
                    console.error(error);
                    this.authentication = Object.assign(Object.assign({}, this.authentication), { reqStatus: 'failed' });
                    return;
                }
                // Fetch the user's sub
                let subResponse;
                try {
                    subResponse = yield get(this.host, this.authToken, `api/subscriptions/2.1/user-subscriptions/${userResponse.user_id}`);
                }
                catch (error) {
                    console.error(error);
                    this.authentication = Object.assign(Object.assign({}, this.authentication), { reqStatus: 'failed' });
                    return;
                }
                // Fetch the available products from Stripe
                let productsResponse;
                try {
                    productsResponse = yield get(this.host, this.authToken, `api/subscriptions/2.0/checkout/products`);
                }
                catch (error) {
                    console.error(error);
                    this.authentication = Object.assign(Object.assign({}, this.authentication), { reqStatus: 'failed' });
                    return;
                }
                // Update final data
                this.authentication = Object.assign(Object.assign({}, this.authentication), { reqStatus: 'succeeded', user: userResponse, subscription: subResponse.user_subscription, products: productsResponse });
            }
        });
    }
    render() {
        return html `<slot></slot>`;
    }
};
__decorate([
    property({ type: String })
], Authentication.prototype, "authToken", void 0);
__decorate([
    property({ type: String })
], Authentication.prototype, "host", void 0);
__decorate([
    provide({ context: authenticationContext })
], Authentication.prototype, "authentication", void 0);
Authentication = __decorate([
    registerCustomElement('grain-authentication')
], Authentication);
export { Authentication };
