import { css } from 'lit';
import { variantTheme } from './theme';
export const styles = css `
:host {
    /*
      @TODO:
      Add these tokens to component-based tokens
      in Figma plug-in
    */
    --button-primary-disabled-surface: var(--grain-global-color-gray-850);
    --button-primary-disabled-text: var(--grain-global-color-black-100);
    --button-secondary-disabled-surface: var(--grain-global-color-black-100);
    --button-secondary-disabled-text: var(--grain-global-color-gray-700);
    --button-secondary-disabled-border: var(--grain-global-color-gray-700);
  }

  /* shared */
  button,a {
    display: block;
    width: max-content;
    border: none;
    border-radius: 100px;
    text-transform: uppercase;
    text-align: left;
    box-shadow: none;
    /* 
      @TODO:
      Styling specificity of the 'button' element was being overwritten
      by the user agent. As a short-term fix, the font-family was declared below,
      but it should be declared in the 'base-styles'
    */
    font-family: 'VSCO Gothic', var(--grain-global-fontFamily-systemFont);

    &:hover {
      cursor: pointer;
    }
  }

  /* shared - disabled */
  button,a {
    &[data-disabled] {
      pointer-events: none;
    }
  }

  /* as anchor tag */
  a {
    text-decoration: none;
  }

  /* full width */
  button
   {
    &.is-full-width {
      width: 100%;
    }
  }

  a
   {
    &.is-full-width {
      width: auto;
    }
  }

  /* small */
  .button--sm {
    padding: 8px 16px;
    font-size: 13px;
    font-weight: 500;
    line-height: 17px;
  }

  /* large */
  .button--lg {
    padding: 11px 16px;
    font-size: 15px;
    font-weight: 500;
    line-height: normal;
  }

  /* primary */
  .button--primary {
    background-color: var(--grain-global-color-button-primary-fill);
    color: var(--grain-global-color-button-primary-text);
    box-shadow: 0 0 0 1px var(--grain-global-color-border-black) inset;

    &:hover {
      background-color: var(--grain-global-color-button-link-primary-active);
    }

    &[data-disabled] {
      background-color: var(--button-primary-disabled-surface);
      color: var(--button-primary-disabled-text);
    }
  }

  /* secondary */
  .button--secondary {
    background-color: var(--grain-global-color-black-100);
    color: var(--grain-global-color-white-100);
    box-shadow: 0 0 0 1px var(--grain-global-color-gray-700) inset;

    &:hover {
      background-color: var(--grain-global-color-gray-900);
    }

    &[data-disabled] {
      background-color: var(--button-secondary-disabled-surface);
      color: var(--button-secondary-disabled-text);
      box-shadow: 0 0 0 1px var(--button-secondary-disabled-border) inset;
    }
  }
`;
export const handleButtonTheme = (variant) => variantTheme[variant];
