import { css } from 'lit';
export const styles = css `
  a {
    display: flex;
    align-items: center;
    padding-top: var(--sidebar-space-1);
    padding-bottom: var(--sidebar-space-1);
    padding-left: var(--sidebar-space-2);
    padding-right: var(--sidebar-space-2);
    color: var(--grain-global-color-gray-550);
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2;
    position: relative;
  }
  a:hover {
    background-color: var(--grain-color-background-2);
  }

  .is-active {
    color: var(--grain-global-color-white-100);

    &:after {
      background-color: var(--grain-global-color-white-100);
      border-radius: 4px;
      content: '';
      display: block;
      height: 24px;
      position: absolute;
      right: 6px;
      top: calc(50% - 12px);
      width: 2px;
    }
  }

  .icon-container {
    width: 24px;
    height: 24px;
    margin-right: var(--icon-margin-right);
  }
`;
