// Returns the "found" products from Stripe CMS
export const parseStripeProducts = (products) => {
    const plusMonthly = products.find(p => p.active &&
        p.name.toLowerCase().includes('plus') &&
        p.default_price.recurring.interval === 'month');
    const plusYearly = products.find(p => p.active &&
        p.name.toLowerCase().includes('plus') &&
        p.default_price.recurring.interval === 'year');
    const proMonthly = products.find(p => p.active &&
        p.name.toLowerCase().includes('pro') &&
        p.default_price.recurring.interval === 'month');
    const proYearly = products.find(p => p.active &&
        p.name.toLowerCase().includes('pro') &&
        p.default_price.recurring.interval === 'year');
    return { plusMonthly, plusYearly, proMonthly, proYearly };
};
