const CheckboxSelectedIcon = () => `<svg
  xmlns="http://www.w3.org/2000/svg"
  width="100%"
  height="100%"
  viewBox="0 0 16 17"
  fill="currentColor">
  <g>
    <path
      d="M0.7,2.5c0-0.7,0.6-1.3,1.3-1.3h12c0.7,0,1.3,0.6,1.3,1.3v12c0,0.7-0.6,1.3-1.3,1.3H2c-0.7,0-1.3-0.6-1.3-1.3
    V2.5z M14,2.5H2v12h12V2.5z"
    />
  </g>
  <g>
    <polygon
      class="checkmark"
      points="7.5,11.4 4.4,8.3 5.3,7.4 7.5,9.6 11.7,5.4 12.6,6.3 	"
    />
  </g>
</svg>`;
export default CheckboxSelectedIcon;
