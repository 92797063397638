import { css } from 'lit';
export const styles = css `
  :host {
    /* @TODO
      Update space & font values from Tokens
    */
    --space-8: 8px;
    --space-10: 10px;
    --space-16: 16px;
    --space-58: 58px;
    --space-90: 90px;

    --sidebar-width-wide: 164px;
    --sidebar-width-narrow: 60px;

    --sidebar-space-1: var(--space-8);
    --sidebar-space-2: var(--space-16);

    --icon-margin-right: var(--space-10);
    --logo-section-spacer: var(--space-90);
    --sidebar-
  }

  .sidebar {
    display: flex;
    flex-direction: column;
    background-color: var(--grain-color-background-1);
    height: 100dvh;
  }

  .sidebar {
    &.is-wide {
      width: var(--sidebar-width-wide);
    }
  }

  /*
    @TODO:

    Design TBD
  */
  .sidebar {
    .nav-text {
      color: var(--grain-global-color-white-100);
      font-size: 13px;
    }

    &.is-narrow {
      width: var(--sidebar-width-narrow);

      .nav-text {
        display: none;
      }
    }
  }

  .logo {
    padding: 20px 0 0 18px;
    margin-bottom: var(--logo-section-spacer);
  }

  .vsco-logo {
    color: var(--grain-global-color-white-100);
  }

  .nav-link-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .nav-link-container--hub {
    margin-top: var(--space-58);
  }


  .icon-external {
    margin-left: 8px;
    margin-bottom: 3px;
  }

  .content {
    margin-top: auto;
    /*
      Clear space for browser chrome URL dialog
      when hovering on links
    */
    padding-bottom: calc(var(--space-10) * 2);

    .is-logged-out {
      padding-left: var(--sidebar-space-2);
      padding-right: var(--sidebar-space-2);
    }
  }

  .content-logged-out-item:not(:last-child) {
    display: block;
    margin-bottom: 16px;
  }
`;
