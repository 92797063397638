const VisibilityIcon = () => `<svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    part="visibility-icon"
    viewBox="0 0 24 24"
    fill="currentColor">
    <path d="M16.9,8.8l3.2,3.2l-2.5,2.5l1.5,1.4L23,12l-4.6-4.6C15.6,4.6,11.5,4,8.2,5.6l1.6,1.5C12.2,6.3,15,6.8,16.9,8.8z" />
    <path
      d="M2.4,5.3l2.7,2.5L1,12l4.6,4.6c2.9,2.9,7.2,3.4,10.6,1.6l2.6,2.5l1.4-1.5L3.8,3.8L2.4,5.3z M14.7,16.7
c-2.5,1.1-5.6,0.6-7.6-1.5L3.8,12l2.8-2.8L14.7,16.7z"
    />
  </svg>`;
export default VisibilityIcon;
