export const buttonVariants = ['primary', 'secondary'];
export const variantTheme = {
    'primary': {
        backgroundColor: 'var(--grain-color-button-primary-fill)',
        color: 'var(--grain-color-button-primary-text)',
        backgroundColorDisabled: 'var(--grain-color-button-primary-disabled-bg)',
        colorDisabled: 'var(--grain-color-button-primary-disabled)',
        backgroundColorHover: 'var(--grain-global-color-button-link-primary-hover)',
        borderColor: 'var(--grain-color-button-primary-border)',
        activeColor: 'var(--grain-color-button-primary-active)',
        hoverColor: 'var(--grain-color-button-primary-hover)',
    },
    'secondary': {
        backgroundColor: 'var(--grain-color-button-primary-fill)',
        color: 'var(--grain-color-button-primary-text)',
        backgroundColorDisabled: 'var(--grain-color-button-primary-disabled-bg)',
        colorDisabled: 'var(--grain-color-button-primary-disabled)',
        backgroundColorHover: 'var(--grain-color-button-primary-hover)',
        borderColor: 'var(--grain-color-button-primary-border)',
        activeColor: 'var(--grain-color-button-primary-active)',
        hoverColor: 'var(--grain-color-button-primary-hover)',
    },
};
